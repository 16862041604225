<template>
    <div class="login_page">
        <div v-if="isLoading" class="overlay">
            <div class="spinner"></div>
        </div>
        <div class="content">
            <div class="form_wrapper">
                <p class="h4 title">{{ $t('login.title') }}</p>
                <p v-if="error" class="error">{{ error }}</p>
                <form @submit.prevent="handleLogin" class="form">
                    <div class="fields">
                        <!-- Поле для email або телефону -->
                        <p>
                            <label for="identifier">{{ $t('login.email') }}:</label>
                            <input
                                v-model="identifier"
                                type="text"
                                id="identifier"
                                :placeholder="$t('login.email_placeholder')"
                                required
                            />
                        </p>
                        <p>
                            <label for="password">{{ $t('login.password') }}:</label>
                            <input
                                :type="showPassword ? 'text' : 'password'"
                                v-model="password"
                                id="password"
                                :placeholder="$t('login.password_placeholder')"
                                required
                            />
                            <span @click="togglePasswordVisibility" class="toggle-password">
                                {{ showPassword ? '🔓' : '🔒' }}
                            </span>
                        </p>
                    </div>
                    <button type="submit">{{ $t('login.submit') }}</button>
                    <a class="password__reset" href="/restore_password/">{{ $t('login.forgot_password') }}</a>
                    <a class="password__reset" href="/registration/">{{ $t('login.register') }}</a>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { updateProductPrice } from '@/api/updateCartPriceService';

export default {
    data() {
        return {
            identifier: '', // Вхід за email або телефон
            password: '',
            error: null,
            showPassword: false,
        };
    },
    methods: {
        ...mapActions('auth', ['login']),
        
        async handleLogin() {
            // Визначаємо, чи це email чи телефон для аутентифікації
            let loginData = { password: this.password };
            
            if (this.isPhoneNumber(this.identifier)) {
                loginData.phone = this.identifier;
            } else {
                loginData.email = this.identifier;
            }

            try {
                // Викликаємо метод login для авторизації
                await this.login(loginData);
                updateProductPrice();
                this.$router.push({ name: 'UserPage' }); 
            } catch (err) {
                this.error = this.$t('login.error_message'); 
            }
        }, 

        // Функція для перевірки, чи це номер телефону
        isPhoneNumber(value) {
            // Приклад перевірки номеру телефону (можна налаштувати під конкретний формат)
            const phoneRegex = /^[+]?[0-9]{10,15}$/;
            return phoneRegex.test(value);
        },

        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        }
    },
};
</script>

<style scoped>
.form .fields > * > input[type="email"], .form .fields > * > input[type="date"], .form .fields > * > input[type="password"], .form .fields > * > input[type="text"], .form .fields > * > textarea, .form .fields > * > select{ 
    width: 315px;
}
.login_page .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 640px;
    margin-bottom: 32px;
}
.login_page .title {
    font-weight: 600;
    margin-bottom: 24px;
    color: var(--clr-3);
}

.login_page input {
    width: 320px;
    max-width: 100%;
}

.form_wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    width: 344px;
    border: 2px solid var(--clr-1);
    border-radius: 4px;
    padding: 48px;
}

.password__reset {
    margin-top: 24px;
}
.error {
    color: red;
    margin-top: 10px;
    text-align: center;
}
.error-message {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 10px;
    text-align: center;
}
.toggle-password {
    position: absolute;
    top: 70%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 20px;
    color: #666; 
}
</style>
