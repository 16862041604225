<!-- src/views/BlogPost.vue -->

<template>
  <div class="content blog__post">
    <!-- BREADCRUMBS -->
    <ul class="breadcrumbs">
      <li><router-link to="/">{{ $t('breadcrambs.home') }}</router-link></li>
      <li><span class="slash">/</span><router-link to="/blog">{{ $t('breadcrambs.articles') }}</router-link></li>
      <li><span class="slash">/</span><span>{{ post.name }}</span></li>
    </ul>


    <!-- ARTICLE CONTENT -->
    <div class="image">
      <img :src="post.image" :alt="post.name" />
    </div>
    <h1 class="name">{{ post.name }}</h1>
    <span class="date">{{ post.date }}</span>
    <div class="richtext" v-html="post.text"></div>

    <!-- SOCIAL SHARE -->
    <div class="social-share">
      <a class="push facebook" :href="'https://www.facebook.com/sharer.php?u=' + postUrl">
        <i class="fa fa-facebook"></i> Facebook
      </a>
      <a class="push twitter" :href="'https://twitter.com/intent/tweet?url=' + postUrl + '&text=' + post.name">
        <i class="fa fa-twitter"></i> Twitter
      </a>
      <a class="push linkedin" :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + postUrl">
        <i class="fa fa-linkedin"></i> LinkedIn
      </a>
      <a class="push whatsapp" :href="'whatsapp://send?text=' + post.name + ' ' + postUrl">
        <i class="fa fa-whatsapp"></i> WhatsApp
      </a>
      <a class="push telegram" :href="'tg://msg_url?url=' + postUrl + '&text=' + post.name">
        <i class="fa fa-telegram"></i> Telegram
      </a>
    </div>
  </div>
</template>

<script>
import { fetchBlogPostBySlug } from '@/api/blogService';

export default {
  name: 'BlogPost',
  data() {
    return {
      post: {},
    };
  },
  computed: {
    postUrl() {
      return window.location.href;
    },
  },
  mounted() {
    this.loadPost();
  },
  methods: {
    async loadPost() {
      try {
        const response = await fetchBlogPostBySlug(this.$route.params.slug);
        this.post = response.data;
      } catch (error) {
        console.error('Помилка завантаження посту:', error);
      }
    },
  },
};
</script>

<style scoped>
/* Стилі, які ти надав */
</style>

<style scoped>
.blog__post {
    position: relative;
    display: block;
    max-width: 960px;
    margin: 0 auto;
}
.blog__post .image {
    position: relative;
    display: block;
    width: calc(100% + 64px);
    height: auto;
    padding-top: 66%;
    margin-left: -32px;
    margin-bottom: 40px;
}
.blog__post .image img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    object-position: center;
}
.blog__post .name {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
}
.blog__post .date {
    display: block;
    color: grey;
    margin-bottom: 32px;
}
.blog__post .richtext {
    display: block;
    margin-bottom: 48px;
}
.blog__post__images {
    position: relative;
    display: block;
    margin-bottom: 64px !important;
}
.blog__post__images .galery {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 16px;
}
.blog__post__images .galery a {
    position: relative;
}
.blog__post__images .galery a img {
    width: 100%;
}
.social-share {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.social-share a {
    display: inline-block;
    margin: 0 10px;
}
@media only screen and (max-width: 767px) {
  .social-share .push {
    margin: 10px;
    width: 40px;
    height: 40px;
    display: inline-block;
  }

  .social-share .fa {
    font-size: 16px;
  }
}


@import '@/assets/css/main/richtext.css';
</style>
    