<template>
  <div class="header__block-nav">
    <div class="content">
      <nav>
        <ul>
          <li v-for="category in categories" :key="category.id">
            <a :href="category.url">{{ category.name }}</a>
          </li>
          
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { fetchFirstLevelCategories } from "@/api/categoryService";
export default {

  data() {
    return {
      categories: [],
    }},
  mounted() {
    this.loadCategories();
  },
  methods: {
    async loadCategories() {
      try {
        const data = await fetchFirstLevelCategories();
        this.categories = data;
      } catch (error) {
        console.error("Не вдалося завантажити категорії:", error);
      }
    },
  }
};
</script>
