import apiClient from '@/api/axios';

const paymentService = {
  async createPay(orderData, productData) {
    console.log('Створення платежу: початок виконання');
    console.log('Дані замовлення:', orderData);
    console.log('Дані продуктів:', productData);
    
    try {
      const response = await apiClient.post('/order/generate-wayforpay-url/', {
        order_data: orderData,
        product_data: productData
      });

      console.log('Отримана відповідь від сервера для createPay:', response.data);
      return response.data;

    } catch (error) {
      console.error('Помилка при ініціації платежу:', error);
      console.error('Статус помилки:', error.response?.status);
      console.error('Тіло відповіді з помилкою:', error.response?.data);
      throw error;
    } finally {
      console.log('Створення платежу: завершення виконання');
    }
  },
  
  async checkPaymentStatus(orderData) {
    console.log('Запит на перевірку статусу оплати:', orderData);
    try {
      const response = await apiClient.post('/order/payment/callback/', orderData);
      console.log('Отримана відповідь від сервера для checkPaymentStatus:', response.data);
      return response.data;
    } catch (error) {
      console.error('Помилка при перевірці статусу оплати:', error);
      throw error;
    }
  },
};

export default paymentService;
