import apiClient from '@/api/axios';

const state = {
  orders: JSON.parse(localStorage.getItem('orders')) || [],
  order: JSON.parse(localStorage.getItem('order')) || null,
};

const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders;
    localStorage.setItem('orders', JSON.stringify(orders)); // Зберігаємо в localStorage
  },
  ADD_ORDER(state, order) {
    state.orders.push(order);
    localStorage.setItem('orders', JSON.stringify(state.orders)); // Оновлюємо localStorage
  },
  SET_ORDER(state, order) {
    state.order = order;
    localStorage.setItem('order', JSON.stringify(order)); // Зберігаємо поточне замовлення в localStorage
  },
  CLEAR_ORDERS(state) {
    state.orders = [];
    state.order = null;
    localStorage.removeItem('orders'); // Видаляємо з localStorage
    localStorage.removeItem('order'); // Видаляємо поточне замовлення з localStorage
  }
};

const actions = {
  setOrder({ commit }, order) {
    commit('SET_ORDER', order);
  },
  async fetchOrders({ commit }) {
    try {
      const response = await apiClient.get('/order/user_orders/');
      commit('SET_ORDERS', response.data);
    } catch (error) {
      console.error('Помилка завантаження замовлень:', error);
    }
  },
  async createOrder({ commit }, orderData) {
    try {
      const response = await apiClient.post('/orders/', orderData);
      commit('ADD_ORDER', response.data);
      return response.data;
    } catch (error) {
      console.error('Помилка створення замовлення:', error);
      throw error;
    }
  },
  clearOrders({ commit }) {
    commit('CLEAR_ORDERS');
  },
};

const getters = {
  getOrders: (state) => state.orders,
  order: (state) => state.order,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
