<template>
  <div class="home-advantages">
    <div class="content">
      <p class="title1 center">{{ $t('homeAdvantages.title') }}</p>
      <ul>
        <li v-for="(advantage, index) in advantages" :key="index">
          <div class="content img">
            <img
              :src="advantage.image"
              :srcset="`${advantage.img_mobile} 480w, ${advantage.image} 1024w`"
              sizes="(max-width: 768px) 100vw, 50vw"
              alt="advantage_img"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { advantages } from '@/data/advantagesData.js';

export default {
  data() {
    return {
      advantages,
      isMobile: false, // Стан мобільного пристрою
    };
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile); // Відстежуємо зміну ширини екрана
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768; // Визначаємо, чи це мобільний пристрій
    },
  },
};
</script>

<style scoped>

@media (max-width: 874px){
  .home-advantages .content ul li{ 
    width: 45%;
  }
}
@media (max-width: 640px){
  .home-advantages .content ul li{ 
    width: 90%;
  }
}
</style>
