<template>
    <div class="product-info">
      <!-- Радіокнопки для перемикання між Описом та Характеристиками -->
      <input type="radio" name="description__type" id="description__type__text" v-model="selectedTab" value="description" checked>
      <input type="radio" name="description__type" id="description__type__properties" v-model="selectedTab" value="properties">
  
      <div class="select__buttons">
        <label for="description__type__text">{{ $t('product.description') }}</label>
        <label for="description__type__properties">{{ $t('product.properties') }}</label>

      </div>
  
      <!-- Відображення опису продукту -->
      <div v-if="selectedTab === 'description'" class="select__block products__description">
        <span class="heading1">{{ product.human }}</span>
        <ul>
          <li v-if="product.description">
            <span class="title">{{ $t('product.description') }}</span>
            <span class="pt16 richtext" v-html="product.description"></span>
          </li>
          <li v-if="product.prescription">
            <span class="title">{{ $t('product.prescription') }}</span>
            <span class="pt16 richtext" v-html="product.prescription"></span>
          </li>
          <li v-if="product.application">
            <span class="title">{{ $t('product.application') }}</span>
            <span class="pt16 richtext" v-html="product.application"></span>
          </li>
          <li v-if="product.composition">
            <span class="title">{{ $t('product.composition') }}</span>
            <span class="pt16 richtext" v-html="product.composition"></span>
          </li>
        </ul>
      </div>
  
      <!-- Відображення характеристик продукту -->
      <div v-else class="products__properties">
        <table>
          <tbody>
            <tr>
              <td>{{ $t('product.category') }}:</td>
                <td>
                    <a :href="`/catalogue/${product.category?.parent_slug}/${product.category?.slug}`">
                    {{ product.category?.name }}
                    </a>
                </td>
            </tr>
            <tr>
              <td>{{ $t('product.brand') }}:</td>
                <td>
                    <a :href="`/brands/${product.brand?.slug}`">
                    {{ product.brand?.name }}
                    </a>
                </td>
            </tr>
           <tr>
            <td>{{ $t('product.serie') }}:</td>
                <td>
                    {{ product.serie }}
                </td>
            </tr>
           <tr>
            <td>{{ $t('product.cosmetics_for') }}:</td>
                <td>
                    <a :href="`/catalogue/${product.category?.parent_slug}`">
                    {{ product.category?.parent_name }}
                    </a>
                </td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProductInfo',
    props: {
      product: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selectedTab: 'description', // За замовчуванням відображаємо Опис
      };
    },
  };
  </script>
  
  <style scoped>
  .products__description {
    position: relative;
    width: 100%;
}
.products__description > .title {
    display: block;
    font-size: var(--h3);
    margin-bottom: 24px;
    text-align: center;
    margin: 48px 0;
}
.products__description ul {
    display: block;
    width: 100%;
    grid-gap: 48px;
    columns: 2;
    margin-bottom: 48px;
}
.products__description ul li > .title {
    position: relative;
    display: block;
    font-size: var(--h5);
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 24px;
}


.products__properties table {
    margin-top: 32px;
    width: 100%;
}
.products__properties table tr {
    border-bottom: 1px solid whitesmoke;
}
.products__properties table tr td {
    padding: 16px 40px 16px 0;
}
.products__properties table tr td:first-of-type {
    width: 33%;
}
.products__properties table tr a {
    color: var(--clr-2);
}




input[name="description__type"] {
    display: none;
}
.select__buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;
}
.select__buttons label {
    padding: 16px 2px;
    border-bottom: 2px solid whitesmoke;
    margin-right: 16px;
    cursor: pointer; 
    width: 100%;
}
.select__buttons label:last-child {
    margin-right: 0px;
}


input#description__type__text:checked ~       .select__buttons label[for="description__type__text"],
input#description__type__properties:checked ~ .select__buttons label[for="description__type__properties"] {
    border-color: var(--clr-1);
    color:  var(--clr-1-spr-drk);
    font-weight: 600;
}



input#description__type__text:checked ~ .products__description,
input#description__type__properties:checked ~ .products__properties {
    display: block;
}
@media(max-width: 960px) {
    .products__description ul {
        columns: 1;
    }
}
@import '@/assets/css/main/richtext.css';
</style>
  