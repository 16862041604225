import apiClient from './axios';


export const updateProductPrice = async () => {
    // Отримання даних з localStorage
    console.log("initial update");
    const cartDataString = localStorage.getItem('cart');

    // Перевірка на null або undefined і парсинг JSON
    let cartData = [];
    if (cartDataString) {
    try {
        cartData = JSON.parse(cartDataString);
    } catch (error) {
        console.error('Помилка парсингу JSON:', error);
    }
    }

    // Створення масиву кодів
    const codes = cartData.map(item => item.code);
    const IS_WHOOSALER_KEY = 'isWhoosaler';

    const isWhoosalerString = localStorage.getItem(IS_WHOOSALER_KEY);
    const isWhoosaler = isWhoosalerString === 'true';

    const dataToSend = {
        codes,
        isWhoosaler
    };

    try {
        const response = await apiClient.post('/core/update-cart-product-prices/', dataToSend);
        const updatedPrices = response.data;
        cartData = cartData.map(item => {
            const newPrice = updatedPrices[item.code];
            return { ...item, price: newPrice };
          });
        localStorage.setItem('cart', JSON.stringify(cartData));
      
    } catch (error) {
        console.error('Помилка при відправці даних:', error);
    }
  };
