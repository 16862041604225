<template>
  <div class="data profile_data">
    <div class="user__orders">
      <p class="title1">{{ $t('orders.title') }}</p>
      <!-- Анімація завантаження -->
      <div v-if="isLoading" class="loader">{{ $t('orders.loading') }}</div>
      <!-- Список замовлень -->
      <ul v-else class="orders">
        <li class="heading_wrapper">
          <div class="heading main">
            <p>{{ $t('orders.status') }}</p>
            <p>{{ $t('orders.deliveryType') }}</p>
            <p>{{ $t('orders.total') }}</p>
            <p class="date">{{ $t('orders.time') }}</p>
          </div>
        </li>
        <li v-for="order in orders" :key="order.id" class="order">
          <div class="heading">
            <p>{{ $t(`orders.statuses.${order.status}`, order.status) }}</p>
            <p>{{ $t(`orders.delivery.${order.pay_type}`, order.pay_type) }}</p>

            <p>{{ order.total }} грн</p>
            <p class="date">{{ order.created }}</p>
          </div>
          <ul class="products">
            <p class="bold title">{{ $t('orders.productsTitle') }}</p>
            <li v-for="product in order.products" :key="product.id" class="product">
              <div class="info">
                <a :href="product.variant.brand_url" class="brand">{{ product.variant.brand }}</a>
                <p class="name">{{ product.variant.name }}</p>
                <p class="quantity">
                  {{ product.quantity }} {{ $t('orders.pieces') }} x {{ product.price }} грн
                </p>
              </div>
              <p class="total">{{ product.total }} грн</p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OrdersComponent',
  data() {
    return {
      isLoading: true, // Стан для завантаження
    };
  },
  computed: {
    ...mapGetters('orders', ['getOrders']),
    orders() {
      return this.getOrders;
    }
  },
  methods: {
    ...mapActions('orders', ['fetchOrders']),
  },
  async mounted() {
    this.isLoading = true; // Вмикаємо завантаження
    await this.fetchOrders();
    this.isLoading = false; // Вимикаємо завантаження після отримання даних
  }
};
</script>

<style scoped>
.info {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  gap: 50px;
}
.user__orders ul.orders {
    display: grid;
    grid-gap: 32px;
}

/* Loader Styles */
.loader {
  font-size: 1.2em;
  text-align: center;
  margin: 20px 0;
}
.loader::after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: var(--clr-2);
  animation: loader-bounce 0.6s infinite alternate;
}

@keyframes loader-bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}

/* Heading */
.user__orders ul.orders li .heading.main p {
    font-weight: 600;
    font-size: var(--h5) !important;
}

.user__orders ul.orders li .heading {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
    border-radius: 16px;
}
.user__orders ul.orders li.order  {
    padding-bottom: 32px;
    border-bottom: 2px solid var(--clr-3);
}

/* Products list */
.user__orders ul.products {
    display: grid;
    grid-gap: 16px;
    margin-top: 24px;
}
.user__orders ul.products .title {
    margin-bottom: 16px;
}
.user__orders ul.products li.product  {
    display: flex;
    align-items: center;
}
.user__orders ul.products li.product .brand,
.user__orders ul.products li.product .name {
    position: relative;
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
}
.user__orders ul.products li.product .brand {
    color: var(--clr-2);
}

@media (max-width: 960px) {
  .user__orders ul.orders {
    grid-gap: 24px;
  }

  .user__orders ul.orders li .heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .user__orders ul.orders li .heading p {
    font-size: 0.9rem; /* Зменшення розміру тексту для компактності */
  }

  .user__orders ul.orders li.order {
    padding-bottom: 24px;
  }

  .user__orders ul.products {
    margin-top: 16px;
  }

  .user__orders ul.products li.product {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    padding: 12px 8px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }

  .user__orders ul.products li.product .info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .user__orders ul.products li.product .info .brand,
  .user__orders ul.products li.product .info .name {
    font-size: 0.85rem; /* Зменшення розміру тексту */
  }

  .user__orders ul.products li.product .info .quantity {
    font-size: 0.8rem;
    color: #555;
  }

  .user__orders ul.products li.product .total {
    text-align: right;
    font-size: 0.9rem;
    font-weight: bold;
  }
}

</style>
