<template>
  <div>
    <!-- Блюр фону -->
    <div v-if="isWishlistOpen" class="blur-background" @click="closeWishlist"></div>

    <!-- Sidebar Wishlist -->
    <div class="sidebar wishlist right" :class="{ active: isWishlistOpen }" id="sidebar_wishlist">
      <!-- TITLE -->
      <div class="title">
        <p class="h3">{{ $t('wishlist.title') }} ❤️</p>
        <img class="close" @click="closeWishlist" src="@/assets/img/icons/close.png" alt="X" />
      </div>

      <!-- MAIN -->
      <div class="main">
        <ul class="wishlist__products">
          <!-- Якщо є товари у вішлісті -->
          <template v-if="wishlistItems.length > 0">
            <li v-for="item in wishlistItems" :key="item.code" class="wishlist__product">
              <a class="image__wrapper" :href="item.url">
                <span class="image">
                  <img class="product_image" :src="item.image || defaultImage" :alt="$t('wishlist.productImageAlt')" />
                </span>
              </a>
              <div class="info">
                <a :href="item.url" class="name">{{ item.name }}</a>
                <span class="price">
                  <button class="buy" @click="moveToCart(item)">{{ $t('wishlist.buyButton') }}</button>
                </span>
                <img
                  class="remove"
                  @click="removeProductFromWishlist(item.code)"
                  src="@/assets/img/icons/svg/Garbage.svg"
                  :alt="$t('wishlist.removeAlt')"
                />
              </div>
            </li>
          </template>

          <!-- Якщо список бажань порожній -->
          <li v-else class="empty">{{ $t('wishlist.emptyMessage') }}</li>
        </ul>
      </div>

      <!-- Додаткові дії (при необхідності) -->
      <div class="actions"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    isWishlistOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      defaultImage: require('@/assets/img/no_image.png')
    };
  },
  computed: {
    ...mapGetters('wishlist', ['wishlistItems'])
  },
  methods: {
    ...mapActions('wishlist', ['removeFromWishlist', 'fetchWishlist']),
    ...mapActions('cart', ['addToCart']), // Підключення addToCart з Vuex модуля cart

    closeWishlist() {
      this.$emit('close'); // Викликає подію закриття сайдбару
    },
    moveToCart(item) {
      console.log(item);
      // Додавання товару з вішліста до кошика
      const productData = {
        id: item.id,
        name: item.name,
        code: item.code,
        price: item.price,
        quantity: 1,
        image: item.image
      };
      this.addToCart(productData); // Виклик додавання в кошик
      this.removeFromWishlist(item.code); // Видалення з вішліста після додавання в кошик
      this.$emit('close_open'); // Закриваємо сайдбар після додавання
    },
    removeProductFromWishlist(productCode) {
      this.removeFromWishlist(productCode); // Викликаємо action для видалення з Vuex
    },
    mounted() {
      this.fetchWishlist(); 
    }
  }
};
</script>


<style scoped>
@import '@/assets/css/shop/wishlist.css';

/* Стилі для розмитого фону */
.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* напівпрозорий фон */
  z-index: 1000;
  backdrop-filter: blur(10px); /* розмиття фону */
}

/* Стилі для Sidebar */
.sidebar {
  z-index: 1001; /* поверх розмитого фону */
}
</style>
