<template>
  <div class="order">
    <h1 class="title1">{{ $t('order.title') }}</h1>
    <ul class="products__list">
      <li v-for="(product) in cartItems" :key="product.id">
        <div class="image">
          <a :href="product.link" class="wrapper">
            <img :src="product.image" :alt="$t('order.product_image_alt')" />
          </a>
        </div>
        <div class="data">
          <div class="info">
            <p class="name">{{ product.name }}</p>
            <p class="cart__price"><span class="price">{{ product.price }} {{ $t('currency') }}</span></p>
          </div>
          <div class="quantity">
            <div class="counter">
              <button class="action minus" @click="decreaseQuantity(product)">-</button>
              <input 
                type="number" 
                v-model.number="product.quantity" 
                min="1" 
                class="product_quantity" 
                @input="updateQuantity({ productId: product.id, quantity: product.quantity })" 
              />
              <button class="action plus" @click="increaseQuantity(product)">+</button>
            </div>
          </div>
          <div class="total">
            <span>{{ product.quantity * product.price }} {{ $t('currency') }}</span>
          </div>
        </div>
        <span class="remove" @click="removeFromCart(product.id)">
          <img src="@/assets/img/icons/svg/Garbage.svg" :alt="$t('order.remove_product')" />
        </span>
      </li>
    </ul>
    <span class="order__total">
      <span>{{ $t('order.total') }}:</span>
      <span class="order__total__num">{{ cartTotalPrice }} {{ $t('currency') }}</span>
    </span>
  </div>
</template>

  <script>
  import { mapGetters, mapActions } from 'vuex';
  
  export default {
    name: "OrderView",
    computed: {
      ...mapGetters('cart', ['cartItems', 'cartTotalPrice']),
    },
    methods: {
      ...mapActions('cart', ['removeFromCart', 'updateQuantity']),
      pushDataLayerEvent() {
      const products = this.cartItems.map(item => ({
        id: item.id,
        code: item.code || '', // Якщо є поле code
        product_name: item.name,
        price: item.price,
        discount_price: item.discount_price || item.price, // Використовуємо discount_price, якщо є
        quantity: item.quantity
      }));

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'viewCart',
          ecommerce: {
            cart: {
              products: products,
              total: this.cartTotalPrice
            }
          }
        });
      }
    },
      decreaseQuantity(product) {
        if (product.quantity > 1) {
          this.updateQuantity({ productId: product.id, quantity: product.quantity - 1 });
        }
      },
      increaseQuantity(product) {
        this.updateQuantity({ productId: product.id, quantity: product.quantity + 1 });
      }
    },
    mounted() {
    this.pushDataLayerEvent();
  }
  };
  </script>
  
  <style scoped>
  ul.products__list {
    position: relative;
    display: block;
    margin-top: 24px;
    margin-bottom: 24px;
}
ul.products__list li {
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 80px auto 12px;
    grid-gap: 24px;
    margin-bottom: 24px;
}

ul.products__list li .data {
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 160px minmax(120px, 540px) 128px auto 12px;
    grid-gap: 24px;
}

ul.products__list li:last-child {
    margin-bottom: 0px;
}

ul.products__list li .image .wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding-top: 100%;
}
ul.products__list li .image .wrapper img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: contain;
    object-position: center;
}


ul.products__list li .info {
    display: grid;
    grid-gap: 8px;
    
}
ul.products__list li .info .price {
    color: grey;
    font-size: var(--h6);
}

ul.products__list li .remove  {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
ul.products__list li .remove img {
    width: 100%;
}
ul.products__list li .image_wrapper {
    position: relative;
    display: block;
    margin-right: 16px;
    width: 96px;
   
}
@media(max-width: 640px) {
    ul.products__list li {
        position: relative;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 24px;
        margin-bottom: 24px;
    }
    
    ul.products__list li .data {
        grid-template-columns: 1fr;
        grid-gap: 24px;
    }
    
}
  </style>
  