<template>
    <main>
        <div class="content order__wrapper">
            <OrderView />
            <OrderForm />
        </div>
    </main>
  </template>
  
  <script>
  import OrderView from "@/components/Order/OrderView.vue";
  import OrderForm from "@/components/Order/OrderForm.vue";
  import { updateProductPrice } from '@/api/updateCartPriceService';
  import { watchEffect } from 'vue';
  import { mapActions } from 'vuex';

  export default {
    components: { OrderView, OrderForm },
    data() {
      return {
        data: null,
      };
    },
    methods: {
      ...mapActions({
      clearOrder: 'orders/clearOrders'
    }),
      async updateData() {
        try {
          const response = updateProductPrice();
          this.data = response.data;
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    },
    mounted() {
      watchEffect(() => {
      this.updateData();
      this.clearOrder();
    });
    },
  };
  </script>
  
<style>
.order__wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 24px;
    margin-bottom: 48px;
}

@media(max-width: 1250px) {
    .order__wrapper {
        grid-template-columns: 1fr;
    }
}
</style>
  