<template>
  <div id="app">
    <!-- Header -->
    <HeaderComponent 
      @toggle-cart="toggleCartList" 
      @toggle-wishlist="toggleWishlist"
    />
    
    <!-- Main Content -->
    <main>
      <router-view 
        @OpenWishList="toggleWishlist"
        @OpenCartList="toggleCartList"
      />
    </main>

    <!-- Popular Products Section -->
    <section class="viewed-products">
      <ProductsCarousel
        :heading="$t('products.popular')"
        :products="products"
        @OpenWishList="toggleWishlist"
        @OpenCartList="openCart"
      />
    </section>

    <!-- Footer -->
    <FooterComponent />

    <!-- Wishlist Sidebar -->
    <WishlistComponent 
      :isWishlistOpen="isWishlistOpen" 
      @close_open="Wish_to_cart"  
      @close="toggleWishlist" 
    />
    
    <!-- Cart Sidebar -->
    <CartComponent 
      :isCartOpen="isCartOpen" 
      @close="closeCart" 
    />

    <!-- Scroll-to-Top Button -->
    <button
      v-show="isVisible"
      @click="scrollToTop"
      class="back-to-top"
      :title="$t('buttons.scroll_to_top')"
    >
      {{ $t('buttons.scroll_to_top') }}
    </button>
  </div>
</template>


<script>
import HeaderComponent from './components/HeaderComponent/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import ProductsCarousel from './components/ProductsSlider.vue';
import WishlistComponent from "@/components/WishlistComponent.vue";
import CartComponent from "@/components/CartComponent.vue";
import { fetchTopProducts } from '@/api/productService';
import { updateProductPrice } from '@/api/updateCartPriceService';
import { watchEffect } from 'vue';


export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
    ProductsCarousel,
    WishlistComponent,
    CartComponent
  },
 
  data() {
    return {
      products: [],
      isWishlistOpen: false,
      isCartOpen: false,
      viewedProducts: [
        { id: 1, name: 'Продукт 1', price: 100, currency: 'грн', image: 'https://fortisbeauty.com.ua/media/shop/variantimages/image-nan-5st-id-1288_s.png' },
        { id: 2, name: 'Продукт 2', price: 200, currency: 'грн', image: 'https://fortisbeauty.com.ua/media/shop/variantimages/image-nan-5st-id-1288_s.png' },
        { id: 3, name: 'Продукт 3', price: 300, currency: 'грн', image: 'https://fortisbeauty.com.ua/media/shop/variantimages/image-nan-5st-id-1288_s.png' },
        { id: 4, name: 'Продукт 4', price: 400, currency: 'грн', image: 'https://fortisbeauty.com.ua/media/shop/variantimages/image-nan-5st-id-1288_s.png' }
      ]
    };
  },
  mounted() {
    this.loadTopProducts();
    window.addEventListener("scroll", this.toggleVisibility);
    watchEffect(() => {
      this.updateData();
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.toggleVisibility);
  },
  methods: {
    async updateData() {
        try {
          const response = updateProductPrice();
          this.data = response;
          console.log('update_cart..');
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      },
    openWishlist() {
      this.isWishlistOpen = false;
    },
    closeWishlist() {
      this.isWishlistOpen = false;
    },
    toggleWishlist() {
      this.isWishlistOpen = !this.isWishlistOpen;
    },
    openCart() {
      this.isCartOpen = true;
    },
    closeCart() {
      this.isCartOpen = false;
    },
    toggleCartList() {
      this.isCartOpen = !this.isCartOpen;
    },
    Wish_to_cart(){
      this.isWishlistOpen = !this.isWishlistOpen;
      this.isCartOpen = !this.isCartOpen;
    },
    toggleVisibility() {
      this.isVisible = window.scrollY > 100;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  
    async loadTopProducts() {
      try {
        const response = await fetchTopProducts();
        this.products = response.data.map(product => ({
          id: product.id,
          name: product.name,
          brand: product.brand.name,
          human: product.human,
          url: `/product/${product.slug}`,
          variants: product.variant.map(variant => ({
            id: variant.id,
            code: variant.code,
            value: variant.value,
            price: variant.price,
            discount_price: variant.discount_price,
            images: variant.images,
            url: variant.url,
            whoosale_price: variant.whoosale_price,
            discount_whoosale_price: variant.discount_whoosale_price,
            stock: variant.stock,
            stock_opt: variant.stock_opt,
          })),
        }));
      } catch (error) {
        console.error("Помилка завантаження новинок:", error);
      }
    }
  }
};
</script>

<style>
@import './assets/css/base/reset.css';
@import './assets/css/base/base.css';
@import './assets/css/main/main.css';
@import './assets/css/main/ui.css';
@import './assets/css/main/colors.css';
@import './assets/css/main/fonts.css';
@import './assets/css/main/forms.css';
@import './assets/css/main/richtext.css';
@import './assets/css/main/sidebar.css';

/* Повний вміст main.css */
body {
    max-width: 100vw;
    position: relative;
    min-width: 320px;
	margin: auto;
    overflow-x: hidden;
    line-height: 1.75;
}
main {
    padding-top: 48px;
    min-height: calc(100vh - 360px);
    overflow: hidden;
    max-width: 100vw;
    margin-bottom: 80px;
}

.content {
	position: relative;
    width: calc(100% - 128px);
	margin: 0 auto;
}

@media (max-width: 960px) {
    .content {
        width: calc(100% - 32px);
        margin: 0 auto;
    }
    main {
        padding-top: 80px;
        min-height: calc(100vh - 360px);
    }
}

@media (max-width: 640px) {
    .content {
        width: calc(100% - 24px);
        margin: 0 auto;
    }
    main {
        padding-top: 80px;
        min-height: calc(100vh - 360px);
    }
}

.block   {display: block;margin-bottom: 24px;}
.inloneblock {display: inline-block;}
.grid    {display: grid;}
.none    {display: none;}
.pointer {cursor: pointer;}
.left {float: left;}
.right {float: right;}

.center {
    text-align: center;
}
.hidden {
    display: none;
}

.divcenter {
    width: auto;
    margin-left: auto;
    margin-right: auto;
}
/* ToUpButton */
.back-to-top {
  border: none;
  display: block !Important;
  color: wheat;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  background-color: var(--clr-2);
  padding: 15px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.back-to-top:hover {
  background-color: var(--clr-3);
}

</style>
