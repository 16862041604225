import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import Page from '@/components/PageComponent.vue';
import HomePage from '../views/HomePage.vue';
import BlogList from '@/components/BlogList.vue';
import BlogPost from '@/components/BlogPost.vue';
import OptPage from '@/views/OptPage.vue';
import ProductPage from '@/views/ProductPage.vue';
import LoginPage from '@/views/auth/AuthLogin.vue';
import UserPage from '@/views/UserPage.vue';
import RegisterPage from '@/views/auth/AuthRegister.vue';
import RegistrationConfirmation from '@/views/auth/RegistrationConfirmation.vue';
import RestorePassword from '@/views/auth/RestorePassword.vue';
import PartnerRegistration from '@/views/auth/PartnerRegistration.vue';
import CataloguePage from '@/views/CataloguePage.vue';
import CatalogueMainPage from '@/views/CatalogueMainPage.vue';
import BrandsPage from '@/views/BrandsPage.vue';
import OrderPage from '@/views/OrderPage.vue';
import OrderSuccessPage from '@/views/SuccessOrderPage.vue';
import PaymentPage from '@/views/PaymentPage.vue';
import CheckPaymentPage from '@/views/CheckPaymentPage.vue';
import PartnerFormPage from '@/views/PartnerForm.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/page/:slug',
    name: 'Page',
    component: Page,
    props: true
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('@/components/ContactComponent.vue')
  },
  {
    path: '/contacts/success',
    name: 'Success',
    component:  () => import('@/components/SuccessPage.vue')
  },
    {
    path: '/blog/',
    name: 'BlogList',
    component: BlogList,
  },
  {
    path: '/blog/:slug',
    name: 'BlogPost',
    component: BlogPost,
    props: true,
  },
  { 
    path: '/opt', 
    name: 'OptPage', 
    component: OptPage 
  },
  {
    path: '/product/:slug', 
    name: 'ProductPage',
    component: ProductPage,
    props: true, 
  },
  { path: '/login', name: 'LoginPage', component: LoginPage },
  {
      path: '/profile',
      name: 'UserPage',
      component: UserPage,
      meta: { requiresAuth: true }
  },
  {
    path: '/registration',
    name: 'RegisterPage',
    component: RegisterPage,
  },
  {
    path: '/registration-confirmation',
    name: 'RegistrationConfirmation',
    component: RegistrationConfirmation,
  },
  {
    path: '/activate/:uid/:token/',
    name: 'ActivateAccount',
    component: () => import('@/views/auth/ActivateAccount.vue')
  },
  {
    path: '/restore_password',
    name: 'RestorePassword',
    component: RestorePassword,
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'PasswordResetConfirm',
    component: () => import('@/views/auth/PasswordResetConfirm.vue'),
    props: true,
  },
  {
    path: '/registration/business',
    name: 'PartnerPegistration',
    component: PartnerRegistration,
  },
  {
    path: '/catalogue/:parent_slug?/:child_slug?',
    name: 'CataloguePage',
    component: CataloguePage,
    props: true,
  },
  {
    path: '/catalogue/',
    name: 'CatalogueMainPage',
    component: CatalogueMainPage,
  },

  {
    path: '/brands/:slug?',
    name: 'BrandsPage',
    component: BrandsPage,
    props: true,
  },
  {
    path: '/order',
    name: 'OrderPage',
    component: OrderPage,
  },
  {
    path: '/order-success',
    name: 'OrderSuccessPage',
    component: OrderSuccessPage,
  },
  {
    path: '/payment',
    name: 'PaymentPage',
    component: PaymentPage
  },
  {
    path: '/check-payment/:slug?',
    name: 'CheckPaymentPage',
    component: CheckPaymentPage
  }, 
  {
    path: '/core/partner',
    name: 'PartnerFormPage',
    component: PartnerFormPage
  },
  
  
  
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
      next({ name: 'LoginPage' });
  } else {
      next();
  }
});

export default router;
