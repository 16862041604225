import { createI18n } from 'vue-i18n';
import en from './en.json';
import ua from './ua.json';

const savedLocale = localStorage.getItem('user-locale') || 'ua';

const i18n = createI18n({
  locale: savedLocale, 
  fallbackLocale: 'en',
  messages: {
    en,
    ua
  }
});

export default i18n;
