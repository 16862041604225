<template>
    <div :class="['sidebar menu', 'left', { active: isOpen }]" id="sidebar_menu">
      <div class="menu__header">
        <a href="/" class="logo">
          <img src="@/assets/img/logo.png" alt="FortisBeauty" />
        </a>
        <a><img   @click="closeMenu" style="width:25px" src="/img/Close.7c0e79a1.svg" alt="X"></a>
       
        <div class="header__account">
          <a href="/profile">
            <img src="@/assets/img/icons/user/Avatar.svg" alt="Користувач" class="ico" />
          </a>
        </div>
        <div class="header__wishlist" @click="toggleWishlist">
          <img src="@/assets/img/icons/svg/Heart.svg" alt="Список побажань">
          <span class="header__qty header__wishlist-qty">0</span>
        </div>
        <select name="set_language"  style="width:20%;" @change="changeLanguage">
          <option value="ua" :selected="currentLanguage === 'ua'">UA</option>
          <option value="en" :selected="currentLanguage === 'en'">EN</option>
        </select>
      </div>
  
      <ul>
        <li class="group-title">{{ $t('footer.catalog') }}:</li>
        
        <div v-for="category in categories" :key="category.id">
          <!-- Виведення батьківських категорій -->
          <li v-if="category.children" class="group-title__wrapper catalogue">
            <div class="tree">
              <ul class="root">
                <li>
                  <span class="title" >
                    <a :href="category.url">{{ category.name }}</a>
                    <img src="@/assets/img/icons/arrow-next.png" alt=">" @click="toggleCategory(category.id)"/>
                  </span>   
                  <ul
                    class="children"
                    :class="{ open: isCategoryOpen(category.id) }"
                    :style="{ right: isCategoryOpen(category.id) ? '0' : '-100%' }"
                    >
                    <div class='close_btn'>
                        <button class="back-btn" @click="toggleCategory(category.id)">
                            <img data-v-3bca233d="" class="close" src="/img/close.c93edc71.png" alt="X">
                        </button>
                    </div>
 
                    <li v-for="child in category.children" :key="child.id">
                        <a :href="child.url" >{{ child.name }}</a>
                    </li>
                    </ul>


                </li>
              </ul>
            </div>
          </li>
        </div>
        <li class="group-title__wrapper catalogue">
            <div class="tree">
              <ul class="root">
                <li>
                  <span class="title" >
                    <a href="/catalogue/nabori-24/nabori-24">{{ $t('sidebar.sets') }}</a>
                   
                  </span>
                </li>
              </ul>
            </div>
          </li>
  
        <!-- Інші пункти меню -->
        <li class="group-title">{{ $t('sidebar.partner') }}:</li>
        <li><a class="item" href="/opt/">{{ $t('sidebar.cooperation') }}</a></li>
        <li><a class="item" href="/core/partner/">{{ $t('sidebar.become_partner') }}</a></li>
        <li><span class="group-title">{{ $t('sidebar.customer') }}</span></li>
        <li><a class="item" href="/brands/">{{ $t('sidebar.brands') }}</a></li>
        <li><a class="item" href="/contacts/">{{ $t('sidebar.contacts') }}</a></li>
        <li><a class="item" href="/about/">{{ $t('sidebar.about') }}</a></li>
        <li><a class="item" href="/blog/">{{ $t('sidebar.blog') }}</a></li>
        <li><a class="item" href="/delivery/">{{ $t('sidebar.delivery') }}</a></li>
        <li><a class="item" href="/povernenna/">{{ $t('sidebar.returns') }}</a></li>
        <li><a class="item" href="/payment/">{{ $t('sidebar.payment') }}</a></li>
        <li><a class="item" href="/terms-of-use/">{{ $t('sidebar.terms_of_use') }}</a></li>
      </ul>
    </div>
  </template>
  
  
<script>
import { fetchCategories } from '@/api/categoryService';

export default {
  name: "MobileSidebar",
  data() {
    return {
      openCategories: [],
      categories: []
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      required: true
    }
  },

  methods: {
    // Завантажуємо категорії через API
    async loadCategories() {
      try {
        const data = await fetchCategories();
        this.categories = data;
      } catch (error) {
        console.error("Не вдалося завантажити категорії:", error);
      }
    },
    // Зміна мови
    changeLanguage(event) {
      const selectedLang = event.target.value;
      this.switchLanguage(selectedLang);
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('user-locale', lang); 
      window.location.reload();
    },
    closeMenu() {
      this.$emit('menu-closed');
    },
    // Відкриття/закриття категорії (тільки одна категорія може бути відкрита одночасно)
    toggleCategory(categoryId) {
    const index = this.openCategories.indexOf(categoryId);
    if (index > -1) {
      this.openCategories.splice(index, 1); // Закриваємо категорію
    } else {
      if (this.openCategories.length > 0) {
        this.categoryStack.push(this.openCategories[0]); // Зберігаємо попередній рівень
      }
      this.openCategories = [categoryId]; // Відкриваємо нову категорію
    }
  },
  goBack() {
    if (this.categoryStack.length > 0) {
      const previousCategory = this.categoryStack.pop(); // Повертаємося до попереднього рівня
      this.openCategories = [previousCategory];
    } else {
      this.openCategories = []; // Якщо немає куди повертатися, закриваємо всі
    }
  },
  isCategoryOpen(categoryId) {
    return this.openCategories.includes(categoryId);
  },

    // Перемикаємо список бажаних товарів
    toggleWishlist() {
      this.$emit('toggle-wishlist'); // WishList
    }
  },
  mounted() {
    this.loadCategories(); 
  }
};

</script>

  
<style scoped>
.sidebar.menu .tree ul > li a{
    width: 100%;
    display: block;
    padding: 20px 8px 20px 24px;
}

.sidebar .close {
    position: static;
    opacity: 0.75;
}
.close_btn {
    display: flex;
    justify-content: flex-end;
}
.back-btn {
    height: 100%;
    border: none;
    display: flex;
    align-items: stretch;
    background-color: var(--clr-1);
    padding: 0 16px;
    background-color: whitesmoke;
    justify-content: flex-end;
    flex-direction: column-reverse;
}
</style>
  