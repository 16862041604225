<template>
    <div class="product__data">
      <span class="brand">{{ product.brand.name }}</span>
      <span class="human">{{ product.human }}</span>
      <span class="name">
        {{ product.name }}{{ product.variant && product.variant[0] ? `, ${product.variant[0].value}` : '' }}
      </span>

    </div>
  </template>
  
  <script>
  export default {
    name: 'ProductData',
    props: {
      product: {
        type: Object,
        required: true,
      },
    },
  };
  </script>
  
<style scoped>
.product__data {
    font-size: 18px;
    position: relative;
    width: calc((100% - 480px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 48px;
}

@media(max-width: 1540px) {
    .product__data{
        position: relative;
        width: calc((100% - 420px) / 2);
    }
}

@media(max-width: 1360px) {
    .product__data{
        width: calc((100% - 320px) / 2);
    }
}
.product__data > * {
    margin-bottom: 12px;
}
.product__data .brand {
    color: var(--clr-2);
    font-weight: 500;
}
.product__data .human {
    font-size: var(--h4);
    font-weight: 600;
}
.product__data .name {
    font-size: var(--h5);
    font-weight: 500;
    color: var(--clr-3);
}
@media (max-width: 960px) {
  .product__data{
    width: 100%;
    margin: 0;
  }

}
</style>
  