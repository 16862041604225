<template>
    <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <div class="header-modal">
          <h3>{{ $t('sidebar.recall_form') }}</h3>
          <button class="close-button" @click="closeModal">
            <img src="@/assets/img/icons/svg/Close.svg" alt="Закрити" class="close-icon" />
          </button>
        </div>
    

        <slot></slot>


      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['isVisible'],
    methods: {
      closeModal() {
        this.$emit('close');
      }
    },
    computed: {
    currentLanguage() {
      return this.$i18n.locale;
    }
  },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background: white;
    padding: 60px;
    border-radius: 8px;
    width: 90%;
    max-width: calc(100% - 400px);
    position: relative;
  }
  .modal-content h3{ 
    margin: 15px auto;
    text-align: center;
  }
  @media (max-width: 1000px){
    .modal-content {
      max-width: calc(100% - 250px);
      padding: 40px;
    }
  }
  .header-modal{ 
  display: flex;
}
  .close-button {
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  </style>
  