<template>
  <div v-if="products.length" class="content">
    <div class="home__products">
      <!-- Заголовок і кнопки -->
      <div class="home__products__heading">
        <span class="title">{{ heading }}</span>
        <div class="buttons">
          <!-- Кнопки для навігації слайдера -->
          <div class="button_prev" @click="slidePrev">
            <img src="@/assets/img/icons/arrow-prev.png" alt="<" />
          </div>
          <div class="button_next" @click="slideNext">
            <img src="@/assets/img/icons/arrow-next.png" alt=">" />
          </div>
        </div>
      </div>

      <!-- Список продуктів у слайдері -->
      <div class="home__products__list">
        <Carousel
          v-model="currentSlide"
          :items-to-show="itemsToShow"
          :wrap-around="true"
          :transition="300"
          :breakpoints="breakpoints"
        >
        <Slide v-for="product in (loading ? skeletons : products)" :key="product.id" class="test">
          <ul class="product-list">
              <ProductCard
                v-if="!loading"
                :key="product.id"
                :product="product"
                @OpenWishList="openWish"
                @OpenCartList="openCart"
              />
              <!-- Скелетон для картки товару -->
              <div v-else class="product-card-skeleton">
                <div class="skeleton-image"></div>
                <div class="skeleton-text"></div>
                <div class="skeleton-button"></div>
              </div>
            </ul>
    
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import ProductCard from '@/components/ProductCard.vue';


export default defineComponent({
  name: 'HomeProducts',
  components: {
    Carousel,
    Slide,
    ProductCard,
  },
  data() {
    return {

      breakpoints: {
        300: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        700: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        1024: {
          itemsToShow: 4, 
          snapAlign: 'start',
        },
      },
    };
  },
  props: {
    heading: {
      type: String,
      default: 'Новинки'
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const loading = ref(true);
    const skeletons = new Array(5).fill({});
    const breakpoints = {
      300: 2,
      700: 3,
      1024: 4,
    };
    const currentSlide = ref(0);
    const itemsToShow = ref(1);
    const computedItemsToShow = computed(() => {
      const width = window.innerWidth;
      if (width >= 1024) return breakpoints[1024];
      if (width >= 700) return breakpoints[700];
      return breakpoints[300];
    });
    setTimeout(() => {
      loading.value = false;
    }, 2000);

    const slideNext = () => {
      currentSlide.value = (currentSlide.value + computedItemsToShow.value) % props.products.length;
    };

    const slidePrev = () => {
      currentSlide.value =
        (currentSlide.value - computedItemsToShow.value) % props.products.length;
    };

    return {
      currentSlide,
      slideNext,
      slidePrev,
      itemsToShow,
      loading,
      skeletons,

    };
  },
  
  methods: {
      openWish(){
        this.$emit('OpenWishList');
      },
      openCart(){
        this.$emit('OpenCartList');
      },
    },
});
</script>




<style scoped>
.carousel__slide {
  align-items: flex-end;
}
.product-list {
  width: 100%;
}
.home__products__heading .title {
    font-size: var(--h2);
    font-weight: 500;
}


.home__products__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
}
.home__products__heading .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.home__products__heading .buttons > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    margin-left: 8px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.home__products__heading .buttons > div:hover {
    background-color: whitesmoke;
}
.home__products__heading .buttons > div img {
    width: 20px;
    height: 20px;
}
@media(max-width: 960px) {
    .home__products__heading .title {
        font-size: var(--h4);
    }
    .home__products__heading .buttons > div {
        width: 40px;
        height: 40px;
    }
    .home__products__heading .buttons > div img {
        width: 16px;
        height: 16px;
    }
}
.product-card-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.skeleton-image {
  width: 200px;
  height: 200px;
  background-color: #e0e0e0;
  border-radius: 8px;
  animation: pulse 1.5s infinite ease-in-out;
}
@media(max-width: 768px) {
  .skeleton-image {
    height: 150px;
    width: 150px;
  }
}

.skeleton-text {
  width: 60%;
  height: 70px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.skeleton-button {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background-color: #e0e0e0;
}

/* Анімація для скелетону */
@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

</style>
