<template>
  <div class="header__block-extra">
    <div class="content">
      <!-- Pages -->
      <ul class="header__pages">
        <router-link to="/brands">
          <li>{{ $t('header.brands') }}</li>
        </router-link>
        <router-link to="/catalogue">
          <li>{{ $t('footer.categories') }}</li>
        </router-link>
        <router-link to="/page/about">
          <li>{{ $t('pages.about') }}</li>
        </router-link>
        <router-link to="/core/partner">
          <li>{{ $t('pages.become_partner') }}</li>
        </router-link>
        <router-link to="/blog">
          <li>{{ $t('pages.blog') }}</li>
        </router-link>
        <router-link to="/page/terms-of-use">
          <li>{{ $t('pages.terms_of_use') }}</li>
        </router-link>

        <!-- Dropdown Menu -->
        <a>
        <li class="dropdown" @mouseover="openDropdown" @mouseleave="closeDropdown">
          <span>{{ $t('FAQ') }}</span>
          <div class="create_dwopdawn" v-show="dropdownOpen">
            <router-link to="/page/delivery">
              <li>{{ $t('pages.delivery') }}</li>
            </router-link>
            <router-link to="/page/return-product">
              <li>{{ $t('pages.return_product') }}</li>
            </router-link>
            <router-link to="/page/payment">
              <li>{{ $t('pages.payment') }}</li>
            </router-link>
          </div>
        </li>
      </a>

        <router-link to="/contacts">
          <li>{{ $t('pages.contacts') }}</li>
        </router-link>
      </ul>

      <!-- Languages -->
      <ul class="header__languages">
        <li :class="{ active: currentLanguage === 'ua' }" @click="switchLanguage('ua')"><span>ua</span></li>
        <li :class="{ active: currentLanguage === 'en' }" @click="switchLanguage('en')"><span>en</span></li>
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  name: 'HeaderExtra',
  data() {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    }
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('user-locale', lang); 
      window.location.reload();
    },
    openDropdown() {
      this.dropdownOpen = true; // Відкриваємо меню
    },
    closeDropdown() {
      this.dropdownOpen = false; // Закриваємо меню
    }
  }
};
</script>


<style scoped>
.header__languages li {
  cursor: pointer;
  padding: 0;
  
}
.header__languages .active {
  font-weight: bold;
  color: var(--clr-primary); 
  text-decoration: underline;
}
.header__pages a li {
  line-height: 1;
}
@media (max-width: 1120px) {
  .header__pages a {
    margin-right: 16px;
  }
}
@media (max-width: 1064px) {
  .header__pages a {
    margin-right: 14px;
    font-size: 12px;
  }
}
@media (max-width: 1026px) {
  .header__pages a {
    margin-right: 12px;
  }
}

/* Стилі для дропдаун меню */
.dropdown {
  position: relative;
}

.create_dwopdawn {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: none;
}

.create_dwopdawn a li {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  text-align: start;
}

.create_dwopdawn a li:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .create_dwopdawn {
  display: block;
}
</style>
