const WISHLIST_KEY = 'wishlist';

// Ініціалізація вішліста з локального сховища
const getWishlistFromStorage = () => JSON.parse(localStorage.getItem(WISHLIST_KEY)) || [];

const state = {
    items: getWishlistFromStorage()
};

const mutations = {
    SET_WISHLIST(state, wishlist) {
        state.items = wishlist;
        localStorage.setItem(WISHLIST_KEY, JSON.stringify(wishlist));
    },
    ADD_TO_WISHLIST(state, product) {
        if (!state.items.find(item => item.code === product.code)) {
            state.items.push(product);
            localStorage.setItem(WISHLIST_KEY, JSON.stringify(state.items));
        }
    },
    REMOVE_FROM_WISHLIST(state, productCode) {
        state.items = state.items.filter(item => item.code !== productCode);
        localStorage.setItem(WISHLIST_KEY, JSON.stringify(state.items));
    }
};

const actions = {
    addToWishlist({ commit }, product) {
        commit('ADD_TO_WISHLIST', product);
    },
    removeFromWishlist({ commit }, productCode) {
        commit('REMOVE_FROM_WISHLIST', productCode);
    },
    fetchWishlist({ commit }) {
        const wishlist = getWishlistFromStorage();
        commit('SET_WISHLIST', wishlist);
    }
};

const getters = {
    wishlistItems: state => state.items,
    wishlistItemCount: state => {
        return state.items.length;
    },
    isProductInWishlist: state => productCode => state.items.some(item => item.code === productCode)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
