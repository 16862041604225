import apiClient from '@/api/axios';

const state = {
  comments: [], // Список коментарів
  replies: {}, // Відповіді до кожного коментаря
};

const mutations = {
  SET_COMMENTS(state, comments) {
    state.comments = comments;
  },
  ADD_COMMENT(state, comment) {
    state.comments.unshift(comment); // Додаємо новий коментар до початку списку
  },
  UPDATE_COMMENT(state, updatedComment) {
    const index = state.comments.findIndex(comment => comment.id === updatedComment.id);
    if (index !== -1) {
      state.comments.splice(index, 1, updatedComment);
    }
  },
  DELETE_COMMENT(state, commentId) {
    state.comments = state.comments.filter(comment => comment.id !== commentId);
  },
  SET_REPLIES(state, { commentId, replies }) {
    state.replies = { ...state.replies, [commentId]: replies };
  },
  ADD_REPLY(state, reply) {
    if (!state.replies[reply.comment]) {
      state.replies[reply.comment] = [];
    }
    state.replies[reply.comment].push(reply);
  },
};

const actions = {
  async fetchComments({ commit }, productId) {
    try {
      const response = await apiClient.get(`/shop/comments/?product_id=${productId}`);
      commit('SET_COMMENTS', response.data);
    } catch (error) {
      console.error('Помилка при отриманні коментарів:', error);
    }
  },
  
  async addComment({ commit }, commentData) {
    try {
      const response = await apiClient.post('/shop/comments/', commentData);
      commit('ADD_COMMENT', response.data);
      console.log(response)
    } catch (error) {
      console.error('Помилка при додаванні коментаря:', error);
      throw error;
    }
  },
  async updateComment({ commit }, { id, data }) {
    try {
      const response = await apiClient.put(`/shop/comments/${id}/`, data);
      commit('UPDATE_COMMENT', response.data);
    } catch (error) {
      console.error('Помилка при оновленні коментаря:', error);
      throw error;
    }
  },
  async deleteComment({ commit }, commentId) {
    try {
      await apiClient.delete(`/shop/comments/${commentId}/`);
      commit('DELETE_COMMENT', commentId);
    } catch (error) {
      console.error('Помилка при видаленні коментаря:', error);
      throw error;
    }
  },
  async likeComment(_, commentId) {
    try {
      if (!commentId) {
        throw new Error("Comment ID is missing for like operation");
      }
      console.log("Лайк для коментаря з ID:", commentId);
      await apiClient.post(`/shop/comments/${commentId}/like/`);
    } catch (error) {
      console.error("Помилка при лайкуванні коментаря:", error);
      throw error; // Перенаправлення помилки назад у компонент
    }
  },

  async dislikeComment(_, commentId) {
    try {
      if (!commentId) {
        throw new Error("Comment ID is missing for dislike operation");
      }
      console.log("Дизлайк для коментаря з ID:", commentId);
      await apiClient.post(`/shop/comments/${commentId}/dislike/`);
    } catch (error) {
      console.error("Помилка при дизлайкуванні коментаря:", error);
      throw error; // Перенаправлення помилки назад у компонент
    }
  },

  async fetchReplies({ commit }, commentId) {
    try {
      const response = await apiClient.get(`/shop/replies/?comment=${commentId}`);
      commit('SET_REPLIES', { commentId, replies: response.data });
    } catch (error) {
      console.error('Помилка при отриманні відповідей:', error);
    }
  },
  async addReply({ commit }, replyData) {
    try {
      const response = await apiClient.post('/shop/replies/', replyData);
      commit('ADD_REPLY', response.data);
    } catch (error) {
      console.error('Помилка при додаванні відповіді:', error);
      throw error;
    }
  },
  async likeReply({ dispatch }, replyId) {
    try {
      await apiClient.post(`/shop/replies/${replyId}/like/`);
      dispatch('fetchReplies'); // Оновлюємо відповіді
    } catch (error) {
      console.error('Помилка при лайкуванні відповіді:', error);
    }
  },
  async dislikeReply({ dispatch }, replyId) {
    try {
      await apiClient.post(`/shop/replies/${replyId}/dislike/`);
      dispatch('fetchReplies'); // Оновлюємо відповіді
    } catch (error) {
      console.error('Помилка при дизлайкуванні відповіді:', error);
    }
  },
};

const getters = {
  getComments: state => state.comments,
  getReplies: state => commentId => state.replies[commentId] || [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
