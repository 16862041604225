<template>
  <div class="content">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs">
      <router-link to="/">{{ $t('breadcrambs.home') }}</router-link>
      <span class="slash">/</span>
      <p>{{ $t('breadcrambs.partner_br') }}</p>
    </div>
  </div>
  <div class="content opt">

    <!-- БАНЕР -->
    <div class="swiper-container home-slider">
      <div class="swiper-slide home-slider__slide">
        <img
          v-if="banner.pc"
          class="lazy banner__pc loading"
          :src="banner.pc.image"
          :alt="$t('opt_page.banner_pc_alt')"
        />
        <img
          v-if="banner.mobile"
          class="lazy banner__mobile"
          :src="banner.mobile.image"
          :alt="$t('opt_page.banner_mobile_alt')"
        />
      </div>
    </div>

    <!-- ОПИС ПРОПОЗИЦІЇ -->
    <h1>{{ $t('opt_page.order_for_partner') }}</h1>
    <div class="richtext" v-html="pageDescription"></div>

    <!-- КНОПКА ПЕРЕХОДУ ДО КАТАЛОГУ -->
    <a class="button" href="/registration/business">{{ $t('opt_page.register_partner') }}</a>
  </div>
</template>


<script>
import { fetchBannerOpt } from '@/api/bannerService';

export default {
  name: 'OptPage',
  data() {
    return {
      banner: {},
      pageDescription: `
        <p>${this.$t('opt_page.description.part1')}</p>
        <p>${this.$t('opt_page.description.part2')}</p>
      `
    };
  },
  mounted() {
    this.loadBanner();
  },
  methods: {
    async loadBanner() {
      try {
        const response = await fetchBannerOpt();
        this.banner = response.data[0]; 
      } catch (error) {
        console.error(this.$t('opt_page.banner_error'), error);
      }
    },
  },
};

</script>

<style scoped>
.opt {
    position: relative;
    display: block;
    max-width: 960px;
    margin: 0 auto 48px auto;
}
.opt .button {
    margin-top: 64px;
}


.home-slider {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 40px;
}
.home-slider__slide {
    position: relative;
    padding-top: calc(100% / 3);
    background-color: whitesmoke;
}

.banner__pc,
.banner__mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  top: 0;
}

.banner__mobile {
  display: none;
}

@media (max-width: 640px) {
  .home-slider__slide {
    padding-top: 200%; /* Співвідношення 2:1 для мобільного */
  }

  .banner__pc {
    display: none;
  }

  .banner__mobile {
    display: block;
  }}

</style>