import apiClient from './axios';

// Метод для отримання всього дерева категорій
export const fetchCategoryTree = async () => {
  try {
    const response = await apiClient.get('/shop/categories/tree/');
    return response.data;
  } catch (error) {
    console.error('Помилка завантаження дерева категорій:', error);
    throw error;
  }
};

// Метод для отримання конкретної категорії за slug
export const fetchCategoryBySlug = async (slug) => {
  try {
    const response = await apiClient.get(`/shop/categories/slug/${slug}/`);
    return response.data;
  } catch (error) {
    console.error(`Помилка завантаження категорії з slug: ${slug}`, error);
    throw error;
  }
};

export const fetchSecondLevelCategories = async () => {
  try {
    const response = await apiClient.get('/shop/categories/tree_childs/');
    return response.data;
  } catch (error) {
    console.error('Помилка завантаження категорій другого рівня:', error);
    throw error;
  }
};

export const fetchFirstLevelCategories = async () => {
  try {
    const response = await apiClient.get('/shop/categories/first_level/');
    console.log(response)
    console.log(12)
    return response.data;
  } catch (error) {
    console.error('Помилка завантаження категорій першого рівня:', error);
    throw error;
  }
};

export const fetchCategories = async () => {
  try {
    const response = await apiClient.get('/shop/categories/');
    return response.data;
  } catch (error) {
    console.error('Помилка завантаження дерева категорій:', error);
    throw error;
  }
};