<template>
    <div class="product__gallery">
      <Carousel 
        :autoplay="false" 
        :loop="true" 
        :perPage="1" 
        :navigationEnabled="true" 
        class="gallery__carousel"
      >
    
      <template #addons v-if="images.length > 1">
      <navigation />
    </template>
        <Slide v-for="(image, index) in images" :key="index" class="gallery__item">
          <img :src="getImageSrc(image)" :alt="`Product image ${index + 1}`" />
        </Slide>
      </Carousel>
    </div>
  </template>
  
  <script>
  import { Carousel, Slide, Navigation } from 'vue3-carousel';
  
  export default {
    name: 'ProductGalleryWrapper',
    components: {
      Carousel,
      Slide,
      Navigation,
    },
    props: {
      images: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        isMobile: window.innerWidth < 768,
      };
    },
    mounted() {
      window.addEventListener("resize", this.updateScreenSize);
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.updateScreenSize);
    },
    methods: {
      updateScreenSize() {
        this.isMobile = window.innerWidth < 768;
      },
      getImageSrc(image) {
        return this.isMobile ? image.l : image.l;
      },
      prev() {
        this.$refs.carousel.prev();
      },
      next() {
        this.$refs.carousel.next();
      },
    },
  };
  </script>
  
  <style scoped>
  .product__gallery {
      position: relative;
      width: 480px;
      margin: 0 24px;
  }
  .gallery__carousel {
      width: 100%;
  }
  .gallery__item img {
      width: 100%;
      max-height: 650px;
      object-fit: contain;
  }
  .carousel-nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      cursor: pointer;
      font-size: 24px;
      padding: 10px;
      z-index: 10;
  }
  .carousel-nav.prev {
      left: 10px;
  }
  .carousel-nav.next {
      right: 10px;
  }
  @media (max-width: 1540px) {
      .product__gallery {
          width: 420px;
      }
  }
  @media (max-width: 1360px) {
      .product__gallery {
          width: 320px;
      }
  }
  @media (max-width: 1360px) {
      .product__gallery {
          width: 100%;
      }
  }
  </style>
  