<template>
  <div class="payment__page">
    <div v-if="isLoading" class="overlay">
      <div class="spinner"></div>
      <p class="loading-message">{{ $t('payment.loading') }}</p>
    </div>
    <div v-else>
      <h1>{{ $t('payment.title', { id: order_data.id }) }}</h1>
      <p v-if="totalAmount">{{ $t('payment.amount_due', { amount: totalAmount }) }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paymentService from '@/api/paymentService';
import orderService from '@/api/orderService';

export default {
  name: "PaymentPage",
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      order_data: 'orders/order',        
      totalAmount: 'orders/totalAmount', 
      cartItems: 'cart/cartItems',      
    }),
  },
  methods: {
    async fetchOrderTotal() {
      try {
        const totalAmount = await orderService.fetchOrderTotal(this.order_data.id);
        this.$store.commit('orders/SET_TOTAL_AMOUNT', totalAmount);
      } catch (error) {
        console.error('Помилка під час отримання загальної суми замовлення:', error);
      }
    },
    async initiatePayment() {
      try {
        // Готуємо productData з інформацією про товари
        const productData = this.cartItems.map(item => ({
          product_name: item.name,
          price: item.price,
          discount_price: item.discount_price || 0,
          qty: item.quantity,
        }));
        // Виклик paymentService для отримання даних оплати
        const paymentData = await paymentService.createPay(this.order_data, productData);

        console.log("Отримані дані для форми оплати:", paymentData);

        // Створення та надсилання форми для WayForPay
        const form = document.createElement("form");
        form.method = "POST";
        form.action = "https://secure.wayforpay.com/pay";
        form.style.display = "none";

        // Додаємо необхідні поля у форму для WayForPay
        for (const key in paymentData) {
          const value = paymentData[key];

          // Перевіряємо, чи є значення масивом, і додаємо кожен елемент окремо
          if (Array.isArray(value)) {
            value.forEach(item => {
              const input = document.createElement("input");
              input.type = "hidden";
              input.name = `${key}[]`; // Додаємо [] до імені для масиву
              input.value = item;
              form.appendChild(input);
            });
          } else {
            // Якщо значення не масив, додаємо його як звичайне поле
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = value;
            form.appendChild(input);
          }
        }

        document.body.appendChild(form);
        console.log("Форма підготовлена до надсилання:", form);
        form.submit();
      } catch (error) {
        console.error('Помилка під час ініціації платежу:', error);
      } finally {
        this.isLoading = false; // Вимкнення спінера після завершення оплати
      }
    },
  },
  async mounted() {
    await this.fetchOrderTotal(); // Отримання загальної суми замовлення
    this.initiatePayment();       // Запуск оплати при завантаженні сторінки
    this.$store.dispatch('cart/clearCart'); //Видаляю дані кошика бо замовлення створене і т.д. і т.п.
  },
};
</script>


<style scoped>
.payment__page {
  text-align: center;
}

/* Стиль для затемненого екрану */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Стиль для спінера */
.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #4caf50;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
