// orderService.js
import apiClient from './axios';

const orderService = {
  // Метод для створення нового замовлення
  async createOrder(orderData) {
    try {
      // Надсилаємо POST-запит на ендпоінт для створення замовлення
      const response = await apiClient.post('/order/orders/', orderData);
      return response.data;  // Повертаємо відповідь з беку
    } catch (error) {
      console.error('Помилка при створенні замовлення:', error);
      throw error;  // Повторно викидаємо помилку для обробки в компоненті
    }
  },

  // Метод для отримання замовлень користувача
  async fetchOrders() {
    try {
      const response = await apiClient.get('/order/orders/');
      return response.data;
    } catch (error) {
      console.error('Помилка завантаження замовлень:', error);
      throw error;
    }
  },

  // Можливий метод для оновлення статусу замовлення
  async updateOrder(orderId, updateData) {
    try {
      const response = await apiClient.patch(`/order/orders/${orderId}/`, updateData);
      return response.data;
    } catch (error) {
      console.error('Помилка оновлення замовлення:', error);
      throw error;
    }
  },

async fetchOrderTotal(orderId) {
  try {
    const response = await apiClient.get(`/order/orders/${orderId}/products/`);
    return response.data.total_sum; // Повертаємо тільки суму
  } catch (error) {
    console.error('Помилка при отриманні загальної суми:', error);
    throw error;
  }
},
};

export default orderService;
