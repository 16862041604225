<template>
  <div class="product__actions">
    <!-- Код товару -->
    <span class="product__code">{{ $t('product.code') }}: {{ activeVariant?.code }}</span>
    <span class="product__code_in_stock">
      <!-- Використовуємо `isWhoosaler` для відображення правильного запасу -->
      <span v-if="activeVariant?.stock_opt > 0">
        {{ $t('product.in_stock') }} {{ activeVariant.stock_opt }}
      </span>
      <span v-else>
        {{ $t('product.out_of_stock') }}
      </span>

    </span>

    <!-- Ціна -->
    <div class="product__price">
      <span class="retail__price">
        <span class="price__wishlist">
          <div class="prices"> 
            <div class="price__wrapper">
              <span v-if="activeVariant?.discount_price > 0" class="price_sale">
                {{ activeVariant?.price }} {{ $t('currency') }}
              </span>
              <span v-else class="price">
                {{ activeVariant?.price }} {{ $t('currency') }}
              </span>
              <span v-if="activeVariant?.discount_price > 0" class="discount_price">
                {{ activeVariant?.discount_price }} {{ $t('currency') }}
              </span>
            </div>
            <div v-if="isWhoosaler" class="whoosale__price">
              <span v-if="activeVariant?.discount_whoosale_price > 0" class="price_sale">
                {{ activeVariant?.whoosale_price }} {{ $t('currency') }}
              </span>
              <span v-else class="price">
                {{ $t('product.wholesale') }}: {{ activeVariant?.whoosale_price }} {{ $t('currency') }}
              </span>
              <span v-if="activeVariant?.discount_whoosale_price > 0" class="discount_price">
                {{ $t('product.wholesale') }}: {{ activeVariant?.discount_whoosale_price }} {{ $t('currency') }}
              </span>
            </div>
          </div>
          <span
            class="produc__wishlist__btn__wrapper add-to-wishlist"
            :data-id="activeVariant?.id"
            @click="addProductToWishlist"
          >
            <img src="@/assets/img/icons/svg/Heart.svg" alt="Wishlist" />
          </span>
        </span>
      </span>
    </div>

    <!-- Варіанти продуктів -->
    <div v-if="product.variant.length > 1" class="product__varaints">
      <a
        v-for="(variant, index) in product.variant"
        :key="variant.id"
        :href="variant.url"
        :class="{ variant: true, active: activeVariant === variant }"
        @click.prevent="selectVariant(variant)"
      >
        <img
          class="image"
          :src="variant.images[0]?.xs"
          :alt="`${$t('product.variant_image')} ${index + 1}`"
        />
        <div class="info">
          <span class="value">
            {{ variant.value }}
            <span v-if="product.unit[0]?.unit">{{ product.unit[0]?.unit }}</span>
          </span>
          <span class="price">{{ variant.price }} {{ $t('currency') }}</span>
        </div>
      </a>
    </div>

    <!-- Дії покупки -->
    <div class="buy__actions">
      <div class="counter">
        <button class="action minus" @click="decrementQuantity">-</button>
        <input
          class="product_quantity"
          type="number"
          v-model="quantity"
          :min="1"
          max="10000"
          @input="validateQuantity"
        />
        <button class="action plus" @click="incrementQuantity">+</button>
      </div>
      <button class="add_to_cart" :class="{ preorder: !isAvailable }" @click="addProductToCart">
        {{ isAvailable ? $t('product.add_to_cart') : $t('product.preorder') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ProductActions',
  props: {
    product: { type: Object, required: true },
  },
  data() {
    return { activeVariant: this.product.variant[0] || null };
  },
  computed: {
    ...mapGetters('cart', ['getProductQuantity']),
    ...mapGetters('auth', ['isWhoosaler']),
    isAvailable() {
      if (this.isWhoosaler) {
        return this.activeVariant?.stock_opt > 0;
      }
      return this.activeVariant?.stock > 0 || this.activeVariant?.stock_opt > 0;
    },
    priceForWishlist() {
      if (this.isWhoosaler) {
        return this.activeVariant?.discount_whoosale_price > 0
          ? this.activeVariant.discount_whoosale_price
          : this.activeVariant?.whoosale_price;
      }
      return this.activeVariant?.discount_price > 0
        ? this.activeVariant.discount_price
        : this.activeVariant?.price;
    },
    quantity: {
      get() {
        return this.getProductQuantity(this.activeVariant.id) || 1;
      },
      set(value) {
        this.updateQuantity({ 
            productId: this.activeVariant.id, 
            quantity: Number(value),
            productData: { // передаємо як productData для відповідності мутації
                id: this.activeVariant.id,
                image: this.activeVariant?.images[0]?.xs,
                name: this.product.name,
                code: this.activeVariant?.code,
                price: this.priceForWishlist
            }
        });
      }
    },
  },
  methods: {
    ...mapActions('wishlist', ['addToWishlist']),
    ...mapActions('cart', ['addToCart', 'updateQuantity']),
    selectVariant(variant) {
      this.$emit('variantSelected', variant);
      this.activeVariant = variant;
    },
    addProductToWishlist() {
      const productData = {
        id: this.activeVariant.id,
        image: this.activeVariant?.images[0]?.xs,
        name: this.product.name,
        code: this.activeVariant?.code,
        price: this.priceForWishlist
      };
      this.addToWishlist(productData);
      this.$emit('OpenWishList');
    },
    onQuantityChange() {
      this.updateQuantity({ productId: this.activeVariant.id, quantity: this.quantity });
    },
    incrementQuantity() {
      this.quantity++;
    },
    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    addProductToCart() {
      const productData = {
        id: this.activeVariant.id,
        image: this.activeVariant?.images[0]?.xs,
        name: this.product.name,
        code: this.activeVariant?.code,
        price: this.priceForWishlist,
        quantity: 1,
      };
      this.addToCart(productData);
      this.$emit('OpenCartList');
    }
  }
};
</script>
  
<style scoped>

.product__actions {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc((100% - 320px) / 2);
    margin-top: 48px;
}
.product__code {
    position: relative;
    display: block;
    color: grey;
    font-size: var(--h6);
    margin-bottom: 16px;
}
.product__code_in_stock {
    display: inline;
    position: relative;
    border: 2px solid #be73c5;
    color: grey;
    font-size: var(--h6);
    margin-bottom: 16px;
}
.product__price {
    margin-bottom: 32px;
}
.product__price .reatail__price {
    position: relative;
    display: flex;
    flex-direction: column;
}
.price__wishlist {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.produc__wishlist__btn__wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.produc__wishlist__btn__wrapper img {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    transition: width 0.3s ease, height 0.3s ease; 
}
.produc__wishlist__btn__wrapper img:hover {
    width: 28px;
    height: 28px;
}
.buy__actions {
    width: 100%;
    margin-top: 16px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 16px;
}
.counter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
button.add_to_cart {
    border: none;
    border-radius: 4px;
    background: var(--clr-2);
    color: white;
    cursor: pointer;
    text-wrap: nowrap;
    
}
button.add_to_cart:hover {
    background: var(--clr-3);
}
.product__varaints {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(148px, 1fr));
    grid-gap: 16px;
    margin-bottom: 32px;
}
.product__varaints a.variant.active {
    border-color: var(--clr-3);
}
.product__varaints a.variant img {
    position: relative;
    width: 48px;
    height: 48px;
    object-fit: contain;
    object-position: center;
    margin-right: 8px;
}
.product__varaints a.variant div {
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
}
.product__varaints a.variant div span {
    position: relative;
    display: block;
    font-weight: 500;
    font-size: var(--h6);
}
.product__varaints a.variant.active div span.value {
    color: var(--clr-3);
    font-weight: 600 !important;
}
.product__varaints a {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid var(--clr-1);
    padding: 4px;
    border-radius: 4px;
}
.product__varaints a.variant.active {
    border-color: var(--clr-3);
}
@media (max-width: 960px) {
    .product__actions{
        width: 100%;
        margin: 0;
    }
}
.price_sale{
  color: grey;
  text-decoration: line-through;
}
.discount_price {
  text-decoration: none;
}
.prices {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

button.preorder{
  border: 2px solid var(--clr-1);
  background-color: transparent;
  color: #000;
}
button.preorder:hover {
  border: 2px solid var(--clr-1);
  background-color: var(--clr-1);
  color: #fff;
}
</style>
  