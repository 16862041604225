

<template>
  <div class="data profile_data">
    <div class="personal__info forms">
      <ul>
        <!-- Особиста інформація -->
        <li class="block user__personal-info">
          <input class="edit" type="checkbox" v-model="isEditing" />
          <div class="heading">
            <p class="title2">{{ $t('personalInfo.title') }}</p>
            <label @click="toggleEditing">
              <img src="/static/img/icons/edit.png" :alt="$t('personalInfo.editAlt')" />
              <span v-if="!isEditing">{{ $t('personalInfo.edit') }}</span>
              <span v-else>{{ $t('personalInfo.cancelEdit') }}</span>
            </label>
          </div>
          <form @submit.prevent="updatePersonalInfo" class="form">
            <div class="fields">
              <div>
                <label for="first_name">{{ $t('personalInfo.firstName') }}:</label>
                <p>
                  <input
                    type="text"
                    id="first_name"
                    v-model="formData.first_name"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="last_name">{{ $t('personalInfo.lastName') }}:</label>
                <p>
                  <input
                    type="text"
                    id="last_name"
                    v-model="formData.last_name"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="father_name">{{ $t('personalInfo.fatherName') }}:</label>
                <p>
                  <input
                    type="text"
                    id="father_name"
                    v-model="formData.father_name"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="phone">{{ $t('personalInfo.phone') }}:</label>
                <p>
                  <input
                    type="text"
                    id="phone"
                    v-model="formData.phone"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="country">{{ $t('personalInfo.country') }}:</label>
                <p>
                  <input
                    type="text"
                    id="country"
                    v-model="formData.country"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="city">{{ $t('personalInfo.city') }}:</label>
                <p>
                  <input
                    type="text"
                    id="city"
                    v-model="formData.city"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
            </div>
            <button type="submit" v-if="isEditing">{{ $t('personalInfo.updateButton') }}</button>
          </form>
        </li>
 

        <!-- Кур'єрська доставка -->
        <li class="block user__delivery-info">
          <input class="edit" type="checkbox" v-model="isEditingCourier" />
          <div class="heading">
            <p class="title2">{{ $t('deliveryInfo.curier') }}</p>
            <label @click="toggleEditingCourier">
              <img src="/static/img/icons/edit.png" :alt="$t('deliveryInfo.editAlt')" />
              <span v-if="!isEditingCourier">{{ $t('deliveryInfo.edit') }}</span>
              <span v-else>{{ $t('deliveryInfo.cancelEdit') }}</span>
            </label>
          </div>
          <form @submit.prevent="updateCourierAddress" class="form">
            <div class="fields">
              <div v-for="(value, key) in courierAddress" :key="key">
                <label :for="key">{{ $t(`deliveryInfo.${key}`) }}:</label>
                <p>
                  <input
                    type="text"
                    :id="key"
                    v-model="courierAddress[key]"
                    :disabled="!isEditingCourier"
                  />
                </p>
              </div>
            </div>
            <button type="submit" v-if="isEditingCourier">{{ $t('deliveryInfo.updateButton') }}</button>
          </form>
        </li>

        <!-- Нова Пошта -->
        <li class="block user__delivery-info">
          <input class="edit" type="checkbox" v-model="isEditingNewPost" />
          <div class="heading">
            <p class="title2">{{ $t('deliveryInfo.newpost') }}</p>
            <label @click="toggleEditingNewPost">
              <img src="/static/img/icons/edit.png" :alt="$t('deliveryInfo.editAlt')" />
              <span v-if="!isEditingNewPost">{{ $t('deliveryInfo.edit') }}</span>
              <span v-else>{{ $t('deliveryInfo.cancelEdit') }}</span>
            </label>
          </div>
          <form @submit.prevent="updateNewPostAddress" class="form">
            <div class="fields">
              <div v-for="(value, key) in newPostAddress" :key="key">
                <label :for="key">{{ $t(`deliveryInfo.${key}`) }}:</label>
                <p>
                  <input
                    type="text"
                    :id="key"
                    v-model="newPostAddress[key]"
                    :disabled="!isEditingNewPost"
                  />
                </p>
              </div>
            </div>
            <button type="submit" v-if="isEditingNewPost">{{ $t('deliveryInfo.updateButton') }}</button>
          </form>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      isEditing: false,
      isEditingCourier: false,
      isEditingNewPost: false,
      formData: {
        first_name: '',
        last_name: '',
        father_name: '',
        phone: '',
        country: '',
        city: '',
      },
      courierAddress: {
        street: '',
        house: '',
        apartment: '',
      },
      newPostAddress: {
        city: '',
        branch: '',
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser', 'getCourierAddress', 'getNewPostAddress']),
  },
  watch: {
    getUser(newUser) {
      if (newUser) this.formData = { ...newUser };
    },
    getCourierAddress(newAddress) {
      this.courierAddress = { ...newAddress };
    },
    getNewPostAddress(newAddress) {
      this.newPostAddress = { ...newAddress };
    },
  },
  methods: {
    ...mapActions('auth', [
      'fetchUser',
      'updateUser',
      'fetchCourierAddress',
      'update_courier',
      'fetchNewPostAddress',
      'update_np',
    ]),
    toggleEditing() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) this.resetPersonalInfo();
    },
    toggleEditingCourier() {
      this.isEditingCourier = !this.isEditingCourier;
      if (!this.isEditingCourier) this.resetCourierAddress();
    },
    toggleEditingNewPost() {
      this.isEditingNewPost = !this.isEditingNewPost;
      if (!this.isEditingNewPost) this.resetNewPostAddress();
    },
    resetPersonalInfo() {
      this.formData = { ...this.getUser };
    },
    resetCourierAddress() {
      this.courierAddress = { ...this.getCourierAddress };
    },
    resetNewPostAddress() {
      this.newPostAddress = { ...this.getNewPostAddress };
    },
    async updatePersonalInfo() {
      try {
        await this.updateUser(this.formData);
        await this.fetchUser();
        this.isEditing = false;
      } catch (error) {
        console.error('Error updating personal info:', error);
      }
    },
    async updateCourierAddress() {
      try {
        await this.update_courier(this.courierAddress);
        await this.fetchCourierAddress();
        this.isEditingCourier = false;
      } catch (error) {
        console.error('Error updating courier address:', error);
      }
    },
    async updateNewPostAddress() {
      try {
        await this.update_np(this.newPostAddress);
        await this.fetchNewPostAddress();
        this.isEditingNewPost = false;
      } catch (error) {
        console.error('Error updating new post address:', error);
      }
    },
  },
  async mounted() {
    await Promise.all([
      this.fetchUser(),
      this.fetchCourierAddress(),
      this.fetchNewPostAddress(),
    ]);
  },
};
</script>


<style scoped>
.heading img {
  display: none;
}
</style>
