<template>
  <div class="home">
    <!-- Десктопна версія -->
    <HomeSlider v-if="!isMobile" />

    <!-- Categories -->
    <div class="home__categories" v-if="!isMobile">
      <div class="content">
        <p class="title1">{{ $t('sidebar.categories')}}</p>
        <ul>
          <li v-for="category in categories" :key="category.id">
            <a :href="category.url">
              <img :src="category.image" :alt="category.name" />
              <p class="name">{{ category.human ? category.human : category.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Products (Новинки) -->
    <ProductsSlider :heading="$t('filter.sort.newest')" :products="products"

    @OpenWishList="openWish"
    @OpenCartList="openCart"
    
    />

    <!-- About Section -->
    <HomeAbout :slogan="slogan" :logo="logo" />

    <!-- Мобільна версія -->
    <HomeSlider v-if="isMobile" />
    
    <!-- Categories -->
    <div class="home__categories" v-if="isMobile">
      <div class="content">
        <p class="title1">{{ $t ('sidebar.categories')}}</p>
        <ul>
          <li v-for="category in categories" :key="category.id">
            <a :href="category.url">
              <img :src="category.image" :alt="category.name" />
              <p class="name">{{ category.human ? category.human : category.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchLatestProducts } from '@/api/productService';
import { fetchFirstLevelCategories } from "@/api/categoryService";
import { defineAsyncComponent } from 'vue';
import HomeAbout from '@/components/HomeAbout.vue';


export default {
  name: 'HomePage',
  components: {
    HomeSlider: defineAsyncComponent(() => import('@/components/HomeSlider.vue')),
    ProductsSlider: defineAsyncComponent(() => import('@/components/ProductsSlider.vue')),
    HomeAbout,
  },
  data() {
    return {
      isMobile: false,
      products: [],
      categories: [],
      logo: require('@/assets/img/logo.png'),
    };
  },
  mounted() {
  this.checkIfMobile();
  if (!this.isMobile) {
    this.loadLatestProducts();
    this.loadCategories();
  }
  window.addEventListener('resize', this.checkIfMobile);
},
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    openWish(){
        this.$emit('OpenWishList');
      },
    openCart(){
        this.$emit('OpenCartList');
      },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    async loadCategories() {
      try {
        const data = await fetchFirstLevelCategories();
        this.categories = data;
      } catch (error) {
        console.error("Не вдалося завантажити категорії:", error);
      }
    },
    async loadLatestProducts() {
      try {
        const response = await fetchLatestProducts();
        this.products = response.data.map(product => ({
          id: product.id,
          name: product.name,
          brand: product.brand.name,
          human: product.human,
          url: `/product/${product.slug}`, 
          variants: product.variant.map(variant => ({
            id: variant.id,
            code: variant.code,
            value: variant.value,
            price: variant.price,
            discount_price: variant.discount_price,
            images: variant.images,
            url: variant.url,
            whoosale_price: variant.whoosale_price,
            discount_whoosale_price: variant.discount_whoosale_price,
            stock: variant.stock,
            stock_opt: variant.stock_opt,
          })),
        }));
      } catch (error) {
        console.error("Помилка завантаження новинок:", error);
      }
    }
  }
};
</script>

<style scoped>
@import '@/assets/css/home/home.css';
</style>
