<template>

    <div class="content">
      <div class="partner_data">
        <h1 class="center">{{ $t ('partner_form.title')}}</h1>
        <form @submit.prevent="submitForm" class="form">
          <!-- Поле прізвище -->
          <div class="form-row">
            <div class="form-group">
              <input v-model="formData.last_name" type="text" class="form-control" :placeholder="$t ('partner_form.last_name')" required />
            </div>
            <div class="form-group">
              <input v-model="formData.first_name" type="text" class="form-control" :placeholder="$t ('partner_form.first_name')" required />
            </div>
          </div>
  
          <!-- Поле по-батькові та email -->
          <div class="form-row">
            <div class="form-group">
              <input v-model="formData.patronymic" type="text" class="form-control" :placeholder="$t ('partner_form.patronymic')" required />
            </div>
            <div class="form-group">
              <input v-model="formData.email" type="email" class="form-control" :placeholder="$t ('partner_form.email')" required />
            </div>
          </div>
  
          <!-- Поле телефон та дата народження -->
          <div class="form-row">
            <div class="form-group">
              <input v-model="formData.phone_number" type="text" class="form-control" placeholder="+__(___)___-__-__*" required />
            </div>
            <div class="form-group">
              <input v-model="formData.date_of_birth" type="date" :placeholder="$t ('partner_form.birthday')" class="form-control" required />
            </div>
          </div>
  
          <!-- Вибір посади та освіти -->
          <div class="form-row">
            <div class="form-group">
              <label for="position">{{ $t ('partner_form.position')}}</label>
              <select v-model="formData.position" class="form-control" required>
                <option value="" disabled selected>{{ $t ('partner_form.select_from_list')}}</option>
       
                <option v-for="position in positions" :key="position.id" :value="position.id">{{ position.name }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="education">{{ $t ('partner_form.education')}}</label>
              <select v-model="formData.education" class="form-control" required>
                <option value="" disabled selected>{{ $t ('partner_form.select_from_list')}}</option>
                <option v-for="education in educations" :key="education.id" :value="education.id">{{ education.name }}</option>
              </select>
            </div>
          </div>
  
          <!-- Поля спеціалізації та місця роботи -->
          <div class="form-row">
            <div class="form-group">
              <label for="specialization">{{ $t ('partner_form.main_specialization')}}</label>
              <select v-model="formData.specialization" class="form-control" required>
                <option value="" disabled selected>{{ $t ('partner_form.select_from_list')}}</option>
                <option v-for="specialization in specializations" :key="specialization.id" :value="specialization.id">{{ specialization.name }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="workplace">{{ $t ('partner_form.working_place')}}</label>
              <select v-model="formData.workplace" class="form-control" required>
                <option value="" disabled selected>{{ $t ('partner_form.select_from_list')}}</option>
                <option v-for="workplace in workplaces" :key="workplace.id" :value="workplace.id">{{ workplace.name }}</option>
              </select>
            </div>
          </div>
  
          <!-- Поле назва клініки та адреса -->
          <div class="form-group">
            <input v-model="formData.clinic_name" type="text" class="form-control" :placeholder="$t ('partner_form.clinic_name')" required />
          </div>
          <div class="form-group">
            <input v-model="formData.address" type="text" class="form-control" :placeholder="$t ('partner_form.address')" required />
          </div>
  
          <!-- Поле соцмережі та сайт клініки -->
          <div class="form-row">
        <div class="form-group">
            <input
            v-model="formData.professional_social_media"
            @blur="formatUrl('professional_social_media')"
            type="text"
            class="form-control"
            :placeholder="$t ('partner_form.professional_social_media')"
            />
        </div>
        <div class="form-group">
            <input
            v-model="formData.clinic_website"
            @blur="formatUrl('clinic_website')"
            type="text"
            class="form-control"
            :placeholder="$t ('partner_form.clinic_website')"
            />
        </div>
        </div>

          <!-- Поле як дізнались -->
          <div class="form-group">
            <label for="how_they_heard_about_us">{{ $t ('partner_form.how_they_heard_about_us')}}</label>
            <select v-model="formData.how_they_heard_about_us" class="form-control">
              <option value="" disabled selected>{{ $t ('partner_form.select_from_list')}}</option>
              <option v-for="item in how_they" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
  
          <!-- Поле завантаження сертифікату -->
          <div class="form-group">
            <label for="profile_certificate_photo">{{ $t ('partner_form.certificate_photo')}}</label>
            <input
                type="file"
                id="profile_certificate_photo"
                @change="handleFileUpload"
                class="form-control file-input"
                accept="image/*"
                />

            </div>

  
          <!-- Кнопка відправки -->
          <button type="submit" enctype="multipart/form-data" class="btn btn-primary">{{ $t ('partner_form.submit')}}</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import PartnerService from "@/api/PartnerService";
  
  export default {
    data() {
      return {
        formData: {
          last_name: "",
          first_name: "",
          patronymic: "",
          email: "",
          phone_number: "",
          date_of_birth: "",
          position: "",
          education: "",
          specialization: "",
          workplace: "",
          clinic_name: "",
          address: "",
          professional_social_media: "",
          clinic_website: "",
          brands_worked_with: "",
          how_they_heard_about_us: "",
          profile_certificate_photo: null,
        },
        positions: [],
        educations: [],
        specializations: [],
        workplaces: [],
        how_they: [],
      };
    },
    mounted() {
  PartnerService.getFormOptions()
    .then((response) => {
      this.positions = response.data.positions;
      this.educations = response.data.educations;
      this.specializations = response.data.specializations;
      this.workplaces = response.data.workplaces;
      this.how_they = response.data.how_they;
      console.log(
        "Positions:", this.positions,
        "Educations:", this.educations,
        "Specializations:", this.specializations,
        "Workplaces:", this.workplaces,
        "How they heard:", this.how_they
      );
    })
    .catch((error) => {
      console.error("Error fetching form options:", error);
    });
},

    methods: {
        formatUrl(field) {
    const url = this.formData[field];
    if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
      this.formData[field] = `https://${url}`;
    }
  },
    handleFileUpload(event) {
        if (event.target.files.length > 0) {
        this.formData.profile_certificate_photo = event.target.files[0];
        }
    },
      submitForm() {
        let formData = new FormData();
        for (let key in this.formData) {
          formData.append(key, this.formData[key]);
        }
  
        PartnerService.submitPartnerForm(formData)
          .then((response) => {
            alert(response.data.success);
            this.resetForm();
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            alert("Помилка при відправці форми.");
          });
      },
      resetForm() {
        this.formData = {
          last_name: "",
          first_name: "",
          patronymic: "",
          email: "",
          phone_number: "",
          date_of_birth: "",
          position: "",
          education: "",
          specialization: "",
          workplace: "",
          clinic_name: "",
          address: "",
          professional_social_media: "",
          clinic_website: "",
          brands_worked_with: "",
          how_they_heard_about_us: "",
          profile_certificate_photo: null,
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .content {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .partner_data {
    width: 100%;
    max-width: 800px;
  }
  .partner_data h1 {
    margin-bottom: 35px;
  }
  .form {
    display: grid;
    gap: 15px;
    width: calc(100% - 24px);
  }
  
  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-control {
    padding: 10px 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  .form-control{
    color: #000;
  }
  .form-control option{
    color: #000;
  }
  .btn {
    padding: 10px 16px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  .file-input {
  display: block;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
  z-index: 1;
}


  @media (max-width: 600px) {
    .form-row {
      grid-template-columns: 1fr;
    }

  }
  </style>
  
