<template>
    <div class="home-advantages">
      <div class="content">
        <div class="title1 center">
      
        </div>
        <ul>
        <li v-for="index in 5" :key="index"> 
            <div class="content img">
            <div class="skeleton skeleton-image" style=" background-color: gray;"></div>
            </div>
        </li>
        </ul>

      </div>
    </div>
  </template>
  
  <script>
  export default {}
  </script>
  
  <style scoped>
  .home-advantages {
    padding: 40px 20px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .title1 {
    width: 50%;
    height: 30px;
    background: #e0e0e0;
    margin-bottom: 24px;
  }
  
  .skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  .skeleton-image {
    width: 100%;
    height: auto;
    min-height: 250px;
    border-radius: 8px;
  }
  

  .skeleton-text {
    width: 100%;
    height: 24px;
    border-radius: 4px;
  }
  
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  </style>
  