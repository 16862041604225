<template>
  <header>
    <HeaderExtra />
    <HeaderMain  @toggle-wishlist="toggleWishlist" @toggle-cart="toogleCart"/>
    <HeaderNav />
  </header>
</template>

<script>
import HeaderExtra from './HeaderExtra.vue';
import HeaderMain from './HeaderMain.vue';
import HeaderNav from './HeaderNav.vue';

export default {
  name: 'HeaderComponent',
  components: {
    HeaderExtra,
    HeaderMain,
    HeaderNav,

  },
  methods: {
    toggleWishlist() {
      this.$emit('toggle-wishlist'); //Wishlist
    },
    toogleCart() {
      this.$emit('toggle-cart'); //Wishlist
    }
  }
};
</script>

<style scoped>
@import '@/assets/css/base/header/header.css';
@import '@/assets/css/base/header/header__menu.css';
</style>
