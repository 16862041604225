<template>
  <div class="content">
    <div v-if="isLoading" class="overlay">
      <div class="spinner"></div>
    </div>
    <div class="user_registration pb64">
      <p class="h1 center pb16">{{ $t('registration.title') }}</p>
      <form @submit.prevent="handleRegister" class="form partner_form" id="userForm" novalidate>
        <div class="fieldset userinfo">
          <p class="title f-headline">{{ $t('registration.personal_info') }}</p>
          <ul class="fields">
            <li>
              <input
                v-model="first_name"
                type="text"
                name="first_name"
                maxlength="30"
                :placeholder="$t('registration.first_name')"
                required
                :class="{ 'input-error': fieldErrors.first_name }"
                @blur="validateField('first_name')"
              />
              <p class="msg" v-if="fieldErrors.first_name">{{ fieldErrors.first_name }}</p>
            </li>
            <li>
              <input
                v-model="last_name"
                type="text"
                name="last_name"
                maxlength="30"
                :placeholder="$t('registration.last_name')"
                required
                :class="{ 'input-error': fieldErrors.last_name }"
                @blur="validateField('last_name')"
              />
              <p class="msg" v-if="fieldErrors.last_name">{{ fieldErrors.last_name }}</p>
            </li>
            <li>
              <input
                v-model="email"
                type="email"
                name="email"
                maxlength="254"
                :placeholder="$t('registration.email')"
                required
                :class="{ 'input-error': fieldErrors.email }"
                @blur="validateField('email')"
              />
              <p class="msg" v-if="fieldErrors.email">{{ fieldErrors.email }}</p>
            </li>
            <li>
              <input
                v-model="phone"
                type="text"
                name="phone"
                maxlength="40"
                :placeholder="$t('registration.phone')"
                required
                :class="{ 'input-error': fieldErrors.phone }"
                @blur="validateField('phone')"
              />
              <p class="msg" v-if="fieldErrors.phone">{{ fieldErrors.phone }}</p>
            </li>
          </ul>
        </div>

        <div class="fieldset password">
          <p class="title f-headline">{{ $t('registration.password_section') }}</p>
          <ul class="fields">
            <li class="password-field">
              <input
                :type="showPassword ? 'text' : 'password'"
                v-model="password1"
                name="password1"
                :placeholder="$t('registration.password1')"
                required
                :class="{ 'input-error': fieldErrors.password1 }"
                @blur="validateField('password1')"
              />
              <span @click="togglePasswordVisibility" class="toggle-password">
                {{ showPassword ? '🔓' : '🔒' }}
              </span>
              <p class="msg" v-if="fieldErrors.password1">{{ fieldErrors.password1 }}</p>
            </li>
            <li class="password-field">
              <input
                :type="showPassword ? 'text' : 'password'"
                v-model="password2"
                name="password2"
                :placeholder="$t('registration.password2')"
                required
                :class="{ 'input-error': fieldErrors.password2 }"
                @blur="validateField('password2')"
              />
              <span @click="togglePasswordVisibility" class="toggle-password">
                {{ showPassword ? '🔓' : '🔒' }}
              </span>
              <p class="msg" v-if="fieldErrors.password2">{{ fieldErrors.password2 }}</p>
            </li>
          </ul>
        </div>

        <p class="msg error" v-if="fieldErrors.general">{{ fieldErrors.general }}</p>
        <button type="submit" :disabled="isLoading">{{ $t('registration.submit') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api/axios";

export default {
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password1: "",
      password2: "",
      fieldErrors: {},
      isLoading: false,
      showPassword: false,
    };
  },
  methods: {
    validateField(field) {
      let value = this[field];
      switch (field) {
        case "first_name":
          this.fieldErrors.first_name = !this.validateName(value)
            ? this.$t("registration.errors.first_name")
            : null;
          break;
        case "last_name":
          this.fieldErrors.last_name = !this.validateName(value)
            ? this.$t("registration.errors.last_name")
            : null;
          break;
        case "email":
          this.fieldErrors.email = !this.validateEmail(value)
            ? this.$t("registration.errors.email")
            : null;
          break;
        case "phone":
          this.fieldErrors.phone = !this.validatePhone(value)
            ? this.$t("registration.errors.phone")
            : null;
          break;
        case "password1":
          this.fieldErrors.password1 = !this.validatePassword(value)
            ? this.$t("registration.errors.password1")
            : null;
          break;
        case "password2":
          this.fieldErrors.password2 =
            this.password1 !== this.password2
              ? this.$t("registration.errors.password_mismatch")
              : null;
          break;
      }
    },
    validateForm() {
      ["first_name", "last_name", "email", "phone", "password1", "password2"].forEach(
        (field) => this.validateField(field)
      );
      return !Object.values(this.fieldErrors).some((error) => error !== null);
    },
    validateName(value) {
      return /^[a-zA-Zа-яА-ЯіІїЇєЄ\s'-]+$/u.test(value);
    },
    validateEmail(value) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    },
    validatePhone(value) {
      return /^[+]?[0-9\s()-]{10,15}$/.test(value);
    },
    validatePassword(value) {
      return value && value.length >= 8;
    },
    async handleRegister() {
      this.fieldErrors = {};
      if (!this.validateForm()) {
        return;
      }

      this.isLoading = true;
      try {
        await apiClient.post("/auth/users/", {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone_number: this.phone,
          password: this.password1,
          re_password: this.password2,
        });
        this.$router.push({ name: "RegistrationConfirmation" });
      } catch (error) {
        if (error.response?.data) {
          this.fieldErrors = error.response.data;
        } else {
          this.fieldErrors.general = this.$t("registration.error");
        }
      } finally {
        this.isLoading = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

  
  <style scoped>
  /* Оригінальні стилі та анімація завантаження */
  .fieldset {
    margin-top: 24px;
  }
  .fieldset ul.fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin-top: 24px;
  }
  .input-error {
    border: 1px solid red;
  }
  .msg {
    color: red;
    font-size: 12px;
  }
  .user_registration {
    display: block;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding-top: 64px;
  }
  .fieldset ul.fields {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin-top: 24px;
  }
  @media(max-width: 526px) {
    .fieldset ul.fields {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  form.partner_form {
    grid-gap: 0px;
  }
  form .messges {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top: 5px solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .password-field {
    position: relative;
  }
  .toggle-password {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 20px;
    color: #666;
  }
  .input-error {
  border-color: red;
}
.msg {
  color: red;
  font-size: 12px;
}
  </style>
  