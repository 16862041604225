<template>
  <div class="content">
    <!-- Breadcrumbs -->
    <div class="breadcrumbs">
      <router-link to="/">{{ $t('breadcrambs.home') }}</router-link>
      <span class="slash"> / </span>
      <router-link to="/catalogue">{{ $t('breadcrambs.catalogue') }}</router-link>
    </div>

    <!-- Заголовок -->
    <div class="catalogue">
      <div class="heading">
        <h1><span class="h1">{{ $t('catalogue.title') }}</span></h1>
      </div>

      <!-- Categories -->
      <div class="home__categories">
        <div class="content">
          <p class="title1">{{ $t('catalogue.categories') }}</p>
          <ul>
            <li v-for="category in categories" :key="category.id">
              <a :href="category.url">
                <img :src="category.image" :alt="category.name" />
                <p class="name">{{ category.human ? category.human : category.name }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Відображення категорій або продуктів з пагінацією -->
      <ul v-if="paginatedData.length > 0 && !child_slug" class="catalogue__categories">
        <li v-for="category in paginatedData" :key="category.id">
          <a :href="`/catalogue/${parent_slug ? `${parent_slug}/${category.slug}` : category.slug}`">
            <div class="image">
              <img :src="category.image" :alt="category.human" />
            </div>
            <span>
              <span class="name">{{ category.name }}</span>
              <span class="qty">({{ category.product_count || 0 }})</span>
            </span>
          </a>
        </li>
      </ul>

      <!-- Пагінація -->
      <div v-if="totalPages > 1" class="pagination">
        <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">
          {{ $t('pagination.previous') }}
        </button>
        <span>
          {{ $t('pagination.page_info', { current: currentPage, total: totalPages }) }}
        </span>
        <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">
          {{ $t('pagination.next') }}
        </button>
      </div>

      <!-- Фільтри (якщо потрібно) -->
      <FilterSidebar 
        :parent_slug="parent_slug" 
        :child_slug="child_slug" 
        @OpenWishList="openWish" 
        @OpenCartList="openCart" 
      />
    </div>
  </div>
</template>

  
  <script>
  import { fetchFirstLevelCategories } from "@/api/categoryService";
  import FilterSidebar from "@/components/FilterSidebar.vue";
  
  export default {
    name: "CataloguePage",
    components: {
      FilterSidebar,
    },
    props: {
      parent_slug: {
        type: String,
        default: ""
      },
      child_slug: {
        type: String,
        default: ""
      }
    },
  
    data() {
      return {
        isMobile: false,
        categories: [],
        category_data: [],
        categoryName: "",
        child_categoryName: "",
        man_count: '',
        currentPage: 1, // Поточна сторінка
        itemsPerPage: 24, // Кількість елементів на сторінку
      };
    },
  
    computed: {
      totalProductCount() {
        return this.category_data.reduce((total, category) => total + (category.product_count || 0), 0);
      },
      slugs() {
        const slugs = {};
        if (this.parent_slug) slugs.parent = this.parent_slug;
        if (this.child_slug) slugs.child = this.child_slug;
        return slugs;
      },
      totalPages() {
        return Math.ceil(this.category_data.length / this.itemsPerPage);
      },
      paginatedData() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.category_data.filter(c => c.product_count > 0).slice(start, end);
      },
    },
  
    mounted() {
        this.checkIfMobile();
        this.loadCategories();
        window.addEventListener('resize', this.checkIfMobile);
    },
  
    watch: {
      currentPage() {
        this.updatePaginatedData();
      }
      
    },
  

  
    methods: {
      openWish() {
        this.$emit('OpenWishList');
      },
      openCart() {
        this.$emit('OpenCartList');
      },
      checkIfMobile() {
        this.isMobile = window.innerWidth <= 768;
      },
      async loadCategories() {
        try {
          const data = await fetchFirstLevelCategories();
          this.categories = data;
          console.log(data);
        } catch (error) {
          console.error("Не вдалося завантажити категорії:", error);
        }
      },

      goToPage(page) {
        if (page >= 1 && page <= this.totalPages) {
          this.currentPage = page;
        }
      },
      updatePaginatedData() {
        console.log("Поточна сторінка:", this.currentPage);
      }
    }
  };
  </script>
  
  
    <style scoped>
    .content {
      position: relative;
      width: calc(100% - 128px);
      margin: 0 auto;
    }
    
    @media (max-width: 960px) {
      .content {
        width: calc(100% - 32px);
      }
      main {
        padding-top: 80px;
        min-height: calc(100vh - 360px);
      }
    }
    
    @media (max-width: 640px) {
      .content {
        width: calc(100% - 24px);
      }
    }
    
    .breadcrumbs {
      display: flex;
      align-items: center;
      padding-bottom: 48px;
    }
    
    .breadcrumbs span.slash {
      color: grey;
      margin: 0 4px;
    }
    
    .breadcrumbs * {
      font-size: var(--h6);
    }
    
    .catalogue .heading {
      display: flex;
      align-items: flex-start;
      margin-top: 8px;
      margin-bottom: 48px;
    }
    
    .catalogue .heading h1 {
      margin-right: 16px;
    }
    
    .catalogue__categories {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      grid-gap: 0px;
      margin-bottom: 32px;
    }
    
    .catalogue__categories a {
      text-decoration: none;
      color: black;
      transition: all 0.2s ease-in-out;
    }
    
    .catalogue__categories li {
      display: flex;
      flex-direction: column;
      padding: 12px;
      border-radius: 4px;
      font-size: var(--h5);
      border: 2px solid white;
      opacity: 0.8;
    }
    .catalogue__categories li:hover {
      opacity: 1;
      border-color: var(--clr-1);
  }
    .catalogue__categories li .image {
      position: relative;
      width: 100%;
      margin-bottom: 8px;
      border-radius: 4px;
      overflow: hidden;
    }
    
    .catalogue__categories li .image img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    
    .catalogue .name {
      margin-right: 4px;
    }
    
    .catalogue .qty {
      font-size: var(--h6);
      color: rgb(207, 207, 207);
    }
    .catalogue .heading .count {
      color: rgb(224, 224, 224);
  }
    </style>
    