<template>
  <div class="data profile_data">
    <div class="company__info forms">
      <p class="title1">{{ $t('profile.companyInfo.title') }}</p>
      <ul>
        <!-- Company Information Section -->
        <li class="block user__company-info">
          <input class="edit" type="checkbox" v-model="isEditingCompanyInfo">
          <div class="heading">
            <p class="title2">{{ $t('profile.companyInfo.heading') }}</p>
            <label @click="toggleEdit('companyInfo')">
              <img src="/static/img/icons/edit.png" :alt="$t('profile.companyInfo.editAlt')" />
              <span v-if="!isEditingCompanyInfo">{{ $t('profile.companyInfo.edit') }}</span>
              <span v-else>{{ $t('profile.companyInfo.cancelEdit') }}</span>
            </label>
          </div>
          <form @submit.prevent="updateCompanyInfo" class="form">
            <div class="fields">
              <div class="name">
                <label for="name">{{ $t('profile.companyInfo.name') }}:</label>
                <p><input type="text" id="name" v-model="companyData.name" :disabled="!isEditingCompanyInfo" /></p>
              </div>
              <div class="code">
                <label for="code">{{ $t('profile.companyInfo.code') }}:</label>
                <p><input type="text" id="code" v-model="companyData.code" :disabled="!isEditingCompanyInfo" /></p>
              </div>
              <div class="email">
                <label for="email">{{ $t('profile.companyInfo.email') }}:</label>
                <p><input type="email" id="email" v-model="companyData.email" :disabled="!isEditingCompanyInfo" /></p>
              </div>
              <div class="iban">
                <label for="iban">{{ $t('profile.companyInfo.iban') }}:</label>
                <p><input type="text" id="iban" v-model="companyData.iban" :disabled="!isEditingCompanyInfo" /></p>
              </div>
              <div class="director">
                <label for="director">{{ $t('profile.companyInfo.director') }}:</label>
                <p><input type="text" id="director" v-model="companyData.director" :disabled="!isEditingCompanyInfo" /></p>
              </div>
              <div class="adress">
                <label for="adress">{{ $t('profile.companyInfo.address') }}:</label>
                <p><input type="text" id="adress" v-model="companyData.adress" :disabled="!isEditingCompanyInfo" /></p>
              </div>
            </div>
            <button type="submit" v-if="isEditingCompanyInfo">{{ $t('profile.companyInfo.updateButton') }}</button>
          </form>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CompanyInfoComponent',
  data() {
    return {
      isEditingRealPrice: false,
      isEditingCompanyInfo: false,
      companyData: {
        name: '',
        code: '',
        email: '',
        iban: '',
        director: '',
        adress: '',
        real_stock: false,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getUserCompany']),
  },
  watch: {
    getUserCompany: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.companyData = { ...newData };
        }
      },
    },
  },
  methods: {
    ...mapActions('auth', ['fetchCompany', 'updateCompany']),
    toggleEdit(section) {
      if (section === 'realPrice') {
        this.isEditingRealPrice = !this.isEditingRealPrice;
      } else if (section === 'companyInfo') {
        this.isEditingCompanyInfo = !this.isEditingCompanyInfo;
      }
      if (this.isEditingCompanyInfo || this.isEditingRealPrice) {
        this.fetchCompany();
      }
    },
    async updateRealPrice() {
      try {
        await this.updateCompany({ real_stock: this.companyData.real_stock });
      } catch (error) {
        console.error("Помилка оновлення реального прайсу:", error);
      }
    },
    async updateCompanyInfo() {
      try {
        await this.updateCompany(this.companyData);
        this.isEditingCompanyInfo = false;
      } catch (error) {
        console.error("Помилка оновлення даних компанії:", error);
      }
    },
  },
  async mounted() {
    await this.fetchCompany();
  },
};
</script>

<style scoped>
/* Стили на основі вашої старої сторінки */
.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title1, .title2 {
  font-size: 1.2rem;
  font-weight: 600;
}
.fields {
  margin-top: 10px;
}
.block {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 16px;
}
.edit {
  display: none;
}
.hidden {
  display: none;
}

.heading img {
  display: none;
}
</style>