// Vuex store для cart.js
const CART_KEY = 'cart';

// Ініціалізація кошика з локального сховища
const getCartFromStorage = () => JSON.parse(localStorage.getItem(CART_KEY)) || [];

const state = {
    items: getCartFromStorage()
};

const mutations = {
    SET_CART(state, cart) {
        state.items = cart;
        localStorage.setItem(CART_KEY, JSON.stringify(cart));
    },
    ADD_TO_CART(state, product) {
        const itemIndex = state.items.findIndex(item => item.id === product.id);
        if (itemIndex > -1) {
            state.items[itemIndex].quantity += product.quantity;
        } else {
            state.items.push(product);
        }
        localStorage.setItem(CART_KEY, JSON.stringify(state.items));

        // Виклик dataLayer для події "addToCart"
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'addToCart',
                ecommerce: {
                    currencyCode: 'UAH',
                    add: {
                        product: [{
                            id: product.id,
                            code: product.code, // Якщо є поле code
                            product_name: product.name,
                            price: product.price,
                            discount_price: product.discount_price || product.price, // Використовуємо discount_price, якщо є
                            quantity: product.quantity
                        }]
                    }
                }
            });
        }
    },
    REMOVE_FROM_CART(state, productId) {
        state.items = state.items.filter(item => item.id !== productId);
        localStorage.setItem(CART_KEY, JSON.stringify(state.items));
    },
    UPDATE_QUANTITY(state, { productId, quantity, productData }) {
        const item = state.items.find(item => item.id === productId);
        if (item) {

            item.quantity = quantity;
        } else {

            state.items.push({
                id: productData.id,
                code: productData.code,
                image: productData.image,
                name: productData.name,
                price: productData.price,
                quantity: quantity,
            });
        }
        localStorage.setItem(CART_KEY, JSON.stringify(state.items));
    },
    
    CLEAR_CART(state) {
        state.items = [];
        localStorage.removeItem(CART_KEY);
    }
};

const actions = {
    addToCart({ commit }, product) {
        commit('ADD_TO_CART', product);
    },
    removeFromCart({ commit }, productId) {
        commit('REMOVE_FROM_CART', productId);
    },
    updateQuantity({ commit }, { productId, quantity, productData }) {
        commit('UPDATE_QUANTITY', { productId, quantity, productData });
    },
    clearCart({ commit }) {
        commit('CLEAR_CART');
    },
    fetchCart({ commit }) {
        const cart = getCartFromStorage();
        commit('SET_CART', cart);
    }
};

const getters = {
    cartItems: state => state.items,
    cartTotalPrice: state => {
        return state.items.reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);
    },
    
    cartItemCount: state => state.items.reduce((total, item) => total + item.quantity, 0),
    getProductQuantity: state => productId => {
        const item = state.items.find(item => item.id === productId);
        return item ? item.quantity : 0;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
