<template>
  <form @submit.prevent="submitForm" class="contact-form">
    <div class="fields">
      <p>
        <input
          v-model="form.subject"
          :class="{ 'invalid': errors.subject }"
          type="text"
          name="subject"
          maxlength="255"
          :placeholder="$t('contactForm.subjectPlaceholder')"
          required
          @blur="validateField('subject')"
        />
        <span v-if="errors.subject" class="error-msg">{{ errors.subject }}</span>
      </p>
      <p>
        <input
          v-model="form.first_name"
          :class="{ 'invalid': errors.first_name }"
          type="text"
          name="first_name"
          maxlength="255"
          :placeholder="$t('contactForm.firstNamePlaceholder')"
          required
          @blur="validateField('first_name')"
        />
        <span v-if="errors.first_name" class="error-msg">{{ errors.first_name }}</span>
      </p>
      <p>
        <input
          v-model="form.email"
          :class="{ 'invalid': errors.email }"
          type="email"
          name="email"
          maxlength="255"
          :placeholder="$t('contactForm.emailPlaceholder')"
          required
          @blur="validateField('email')"
        />
        <span v-if="errors.email" class="error-msg">{{ errors.email }}</span>
      </p>
      <p>
        <input
          v-model="form.phone"
          :class="{ 'invalid': errors.phone }"
          type="text"
          name="phone"
          maxlength="255"
          :placeholder="$t('contactForm.phonePlaceholder')"
          required
          @blur="validateField('phone')"
        />
        <span v-if="errors.phone" class="error-msg">{{ errors.phone }}</span>
      </p>
      <p>
        <textarea
          v-model="form.text"
          :class="{ 'invalid': errors.text }"
          name="text"
          cols="40"
          rows="10"
          :placeholder="$t('contactForm.textPlaceholder')"
          style="resize: none;"
          required
          @blur="validateField('text')"
        ></textarea>
        <span v-if="errors.text" class="error-msg">{{ errors.text }}</span>
      </p>
    </div>
    <button type="submit">{{ $t('contactForm.submitButton') }}</button>
  </form>
</template>

<script>
import apiClient from "@/api/axios";

export default {
  data() {
    return {
      form: {
        subject: "",
        first_name: "",
        email: "",
        phone: "",
        text: "",
      },
      errors: {
        subject: null,
        first_name: null,
        email: null,
        phone: null,
        text: null,
      },
    };
  },
  methods: {
    validateField(field) {
      let value = this.form[field];
      switch (field) {
        case "subject":
          this.errors.subject = !this.validateText(value)
            ? this.$t("contactForm.errors.subject")
            : null;
          break;
        case "first_name":
          this.errors.first_name = !this.validateName(value)
            ? this.$t("contactForm.errors.first_name")
            : null;
          break;
        case "email":
          this.errors.email = !this.validateEmail(value)
            ? this.$t("contactForm.errors.email")
            : null;
          break;
        case "phone":
          this.errors.phone = !this.validatePhone(value)
            ? this.$t("contactForm.errors.phone")
            : null;
          break;
        case "text":
          this.errors.text = !this.validateText(value)
            ? this.$t("contactForm.errors.text")
            : null;
          break;
      }
    },
    validateForm() {
      // Перевіряємо всі поля
      Object.keys(this.form).forEach((field) => this.validateField(field));
      return !Object.values(this.errors).some((error) => error !== null);
    },
    validateText(value) {
      const textRegex = /^[a-zA-Zа-яА-ЯіІїЇєЄ0-9\s.,!?-]+$/u;
      return value && textRegex.test(value);
    },
    validateName(value) {
      const nameRegex = /^[a-zA-Zа-яА-ЯіІїЇєЄ\s'-]+$/u;
      return value && nameRegex.test(value);
    },
    validateEmail(value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return value && emailRegex.test(value);
    },
    validatePhone(value) {
      const phoneRegex = /^[+]?[0-9\s()-]{10,15}$/;
      return value && phoneRegex.test(value);
    },
    async submitForm() {
      if (!this.validateForm()) {
        alert(this.$t("contactForm.validationError"));
        return;
      }

      try {
        await apiClient.post("/core/recal_me/", this.form);
        alert(this.$t("contactForm.successMessage"));
        this.clearForm();
        this.$emit("formSubmitted");
      } catch (error) {
        console.error("Помилка при відправці форми:", error);
        alert(this.$t("contactForm.errorMessage"));
      }
    },
    clearForm() {
      this.form = { subject: "", first_name: "", email: "", phone: "", text: "" };
      this.errors = { subject: null, first_name: null, email: null, phone: null, text: null };
    },
  },
};
</script>


<style scoped>
.contact-form {
  display: grid;
  grid-auto-columns: auto;
  grid-gap: var(--px16);
  max-width: var(--px1140);
  height: calc(100% - 160px);
  grid-template-columns: repeat(1, 1fr);
}

.fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
}
.fields p {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.fields p input,
.fields p textarea {
  width: calc(100% - 32px);
  padding: 16px;
  border: none;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px var(--clr-1);
  font-size: var(--h5);
  transition: box-shadow 0.2s ease-in-out;
  outline: none;
}
.fields p.invalid input,
.fields p.invalid textarea {
  box-shadow: inset 0 0 0 2px #ff0000; /* Червона рамка для невалідних полів */
}
.fields p:last-of-type {
  grid-column: 1 / -1; /* textarea займе всю ширину сітки */
}
.fields p textarea {
  resize: vertical;
  font-family: inherit;
}

.contact-form button {
  cursor: pointer;
  padding: 16px;
  border: 0;
  border-radius: 4px;
  background-color: #ff2261 !important;
  width: 100% !important;
  color: #fff;
  margin-top: 24px;
  transition: all 0.2s ease-in-out;
  float: right;
}
.contact-form button:hover { 
  background-color: var(--clr-3) !important;
}
.invalid {
  border-color: red;
}
.error-msg {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

</style>
