import apiClient from './axios';

export default {
    // Отримати списки для селектів
    getFormOptions() {
        return apiClient.get('/core/partner/');
    },

    // Надіслати форму
    submitPartnerForm(formData) {
        return apiClient.post('/core/partner/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};
