import axios from 'axios';
import store from '@/store';
import router from '@/router';

const apiClient = axios.create({
    baseURL: 'https://fortisbeauty.com.ua/api/v1',
    // baseURL: 'http://127.0.0.1:8000/api/v1',
    headers: {
        'Content-Type': 'application/json',
    },
    
});

// Перевірка токена на наявність кирилиці
function containsCyrillic(token) {
    const cyrillicRegex = /[А-Яа-яЁёЇїІіЄєҐґ]/;
    return cyrillicRegex.test(token);
}

// Інтерцептор для додавання токена до запитів
apiClient.interceptors.request.use(
    async (config) => {
        const token = store.state.auth.accessToken;
        const userLocale = localStorage.getItem('user-locale') || 'en'; 
        config.headers['Accept-Language'] = userLocale;

        if (token) {
            if (containsCyrillic(token)) {
                console.error('Токен містить кирилицю. Виконуємо логаут...');
                store.dispatch('auth/logout');
                router.push('/login');
                throw new Error('Токен містить недійсні символи.');
            }

            config.headers['Authorization'] = `Bearer ${token}`;
        } else {
            localStorage.removeItem('isWhoosaler');
        }
        return config;
    },
    (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                console.log('401 помилка. Спроба оновити токен...');
                store.dispatch('auth/refreshAccessToken');
                const newToken = store.state.auth.accessToken;

                if (newToken) {
                    if (containsCyrillic(newToken)) {
                        console.error('Новий токен містить кирилицю. Виконуємо логаут...');
                        store.dispatch('auth/logout');
                        router.push('/login');
                        throw new Error('Новий токен містить недійсні символи.');
                    }

                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                    console.log('Токен оновлено успішно. Повторний запит...');
                    return apiClient(originalRequest);
                } else {
                    console.error('Новий токен не отримано.');
                    throw new Error('Не вдалося оновити токен.');
                }
            } catch (refreshError) {
                console.error('Помилка при оновленні токена:', refreshError);
                store.dispatch('auth/logout');
                router.push('/login');
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default apiClient;