<template>
    <div class="content">
      <p class="h1 mt48 center">{{ $t('registration_confirmation.thank_you') }}</p>
      <p class="h2 mt16 mb16 center">
        {{ $t('registration_confirmation.activation_instructions') }}
      </p>
      <img
        style="width: 100%; max-width: 640px; display: block; margin: 32px auto; border-radius: 4px;"
        src="@/assets/img/sahmpoo.gif"
        alt="sahmpoo"
      />
      <a class="h2 color-2 block center mt16 mb16" href="/">{{ $t('registration_confirmation.go_to_home') }}</a>
    </div>
  </template>
  
<script>
export default {
    name: 'RegistrationConfirmation',
};
</script>

<style scoped>
</style>
