<template>
    <div class="content form__outer password__restore">
        <div class="form__wrapper">
            <h1 class="h4">{{ $t('login.reset_password') }}</h1>
            <p class="helptext">{{ $t('login.reset_info') }}</p>
            <p v-if="successMessage" class="success">{{ successMessage }}</p>
            <p v-if="error" class="error">{{ error }}</p>
            <form @submit.prevent="handleRestorePassword" class="form">
                <p>
                    <input
                        v-model="email"
                        type="email"
                        placeholder="Email *"
                        required
                        :class="{ 'input-error': error }"
                    />
                </p>
                <button type="submit" :disabled="loading">
                    <div v-if="loading" class="loader"></div>
                    <span v-else>{{ $t('login.send_link')}}</span>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import apiClient from '@/api/axios';

export default {
    data() {
        return {
            email: '',
            error: null,
            successMessage: '',
            loading: false,
        };
    },
    computed: {
    currentLanguage() {
      return this.$i18n.locale;
    }
  },
    methods: {
        async handleRestorePassword() {
            console.log('Натиснута кнопка для відновлення пароля');  // Лог натискання кнопки
            this.error = null;
            this.successMessage = '';
            this.loading = true;
            
            try {
                // Запит на відновлення пароля з актуальним ендпоінтом
                const response = await apiClient.post('/auth/users/reset_password/', { email: this.email });
                console.log('Запит відновлення пароля успішний, результат:', response);  // Лог успішного результату
                this.successMessage = 'Лист для відновлення пароля надіслано на вашу електронну пошту.';
            } catch (error) {
                console.error('Помилка відновлення пароля:', error);  // Лог помилки відновлення пароля
                this.error = error.response?.data?.email ? error.response.data.email[0] : 'Щось пішло не так. Перевірте правильність введення email.';
            } finally {
                this.loading = false;
                console.log('Завершення запиту відновлення пароля');  // Лог завершення запиту
            }
        },
    },
};
</script>

<style scoped>
.password__restore .form__wrapper {
    max-width: 344px;
    padding: 32px;
    background-color: #fff;
    border: 2px solid var(--clr-1);
    border-radius: 4px;
    text-align: center;
}
.password__restore .h4 {
    margin-bottom: 8px;
}
.success, .error {
    margin-top: 10px;
    font-size: 14px;
}
.error { color: red; }
.success { color: green; }

/* Анімація завантаження */
.loader {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 0.6s linear infinite;
    position: relative;
}

.loader:before, .loader:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fff;
    top: -6px;
    left: -6px;
    width: 36px;
    height: 36px;
    animation: spin-reverse 0.9s linear infinite;
}

.loader:after {
    top: -12px;
    left: -12px;
    width: 48px;
    height: 48px;
    animation-duration: 1.2s;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spin-reverse {
    0% { transform: rotate(360deg); }
    100% { transform: rotate(0deg); }
}

.content {
    display: flex;
    position: relative;
    width: calc(100% - 128px);
    justify-content: center;
    margin: 0 auto;
}

.form button span {
    color: white;
}
</style>
