<template>
  <div class="content">
    <!-- Breadcrumbs -->
    <div class="breadcrumbs">
      <router-link to="/">{{ $t('breadcrambs.home') }}</router-link>
      <span class="slash"> / </span>
      <router-link to="/catalogue">{{ $t('breadcrambs.catalogue') }}</router-link>
      <span v-if="categoryName" class="slash"> / </span>
      <router-link v-if="parent_slug" :to="`/catalogue/${parent_slug}`">{{ categoryName }}</router-link>
      <span v-if="child_slug" class="slash"> / </span>
      <span v-if="child_slug">{{ child_categoryName }}</span>
    </div>

    <!-- Заголовок -->
    <div class="catalogue">
      <div class="heading">
        <h1><span class="h1">{{ $t('catalogue.title') }}</span></h1>
        <div v-if="man_count" class="count h1 total_p roducts">{{ man_count }}</div>
      </div>

      <!-- Відображення категорій або продуктів з пагінацією -->
      <ul v-if="paginatedData.length > 0 && !child_slug" class="catalogue__categories">
        <li v-for="category in paginatedData" :key="category.id">
          <a :href="`/catalogue/${parent_slug ? `${parent_slug}/${category.slug}` : category.slug}`">
            <div class="image">
              <img :src="category.image" :alt="category.human" />
            </div>
            <span>
              <span class="name">{{ category.name }}</span>
              <span class="qty">({{ category.product_count || 0 }})</span>
            </span>
          </a>
        </li>
      </ul>

      <!-- Пагінація -->
      <div v-if="totalPages > 1" class="pagination">
        <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">  {{ $t('pagination.previous') }}</button>
        <span>Сторінка {{ currentPage }} з {{ totalPages }}</span>
        <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">  {{ $t('pagination.next') }}</button>
      </div>

     <!-- У батьківському компоненті, наприклад, CataloguePage.vue -->
    <FilterSidebar :parent_slug="parent_slug" :child_slug="child_slug" 
      @OpenWishList="openWish"
      @OpenCartList="openCart"
    />

    </div>
  </div>
</template>

<script>
import { fetchCategoryTree, fetchCategoryBySlug } from "@/api/categoryService";
import FilterSidebar from "@/components/FilterSidebar.vue";

export default {
  name: "CataloguePage",
  components: {
    FilterSidebar,
  },
  props: {
  parent_slug: {
    type: String,
    default: ""
  },
  child_slug: {
    type: String,
    default: ""
  }
},

  data() {
    return {
      category_data: [],
      categoryName: "",
      child_categoryName: "",
      man_count: '',
      currentPage: 1, // Поточна сторінка
      itemsPerPage: 24, // Кількість елементів на сторінку
    };
  },
  computed: {
    totalProductCount() {
      return this.category_data.reduce((total, category) => total + (category.product_count || 0), 0);
    },
    slugs() {
      const slugs = {};
      if (this.parent_slug) slugs.parent = this.parent_slug;
      if (this.child_slug) slugs.child = this.child_slug;
      return slugs;
    },
    totalPages() {
      return Math.ceil(this.category_data.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.category_data.filter(c => c.product_count > 0).slice(start, end);
    },
  },
  watch: {
    parent_slug: 'fetchData',
    child_slug: 'fetchData',
    currentPage() {
      this.updatePaginatedData();
    }
  },
  async created() {
    console.log("Компонент створено. Виконується fetchData.");
    await this.fetchData();
  },
  methods: {
    openWish(){
        this.$emit('OpenWishList');
      },
      openCart(){
        this.$emit('OpenCartList');
      },
    async fetchData() {
      try {
        if (!this.parent_slug) {
          console.log("Завантаження всіх батьківських категорій.");
          this.pageTitle = "Каталог";
          const response = await fetchCategoryTree();
          this.category_data = response;
          console.log("Батьківські категорії завантажено:", response);
        } else if (this.parent_slug && !this.child_slug) {
          console.log(`Завантаження категорії з slug: ${this.parent_slug}`);
          const response = await fetchCategoryBySlug(this.parent_slug);
          this.pageTitle = response.human;
          this.categoryName = response.name;
          this.category_data = response.children || [];
          this.man_count = response.product_count;
          console.log("Дочірні категорії для батьківської категорії завантажено:", response.children);
        } else if (this.parent_slug && this.child_slug) {
          console.log(`Завантаження батьківської категорії з slug: ${this.parent_slug}`);
          const response = await fetchCategoryBySlug(this.parent_slug);
          this.categoryName = response.name;

          console.log(`Завантаження дочірньої категорії з slug: ${this.child_slug}`);
          const finalResponse = await fetchCategoryBySlug(this.child_slug);
          this.child_categoryName = finalResponse.name;
          this.pageTitle = finalResponse.human;
          this.category_data = [finalResponse];
          this.man_count = finalResponse.product_count;
          console.log("Дочірня категорія завантажена:", finalResponse);
        }
      } catch (error) {
        console.error("Помилка завантаження даних:", error);
      }
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    updatePaginatedData() {
      console.log("Поточна сторінка:", this.currentPage);
    }
  }
};
</script>


  <style scoped>
  .content {
    position: relative;
    width: calc(100% - 128px);
    margin: 0 auto;
  }
  
  @media (max-width: 960px) {
    .content {
      width: calc(100% - 32px);
    }
    main {
      padding-top: 80px;
      min-height: calc(100vh - 360px);
    }
  }
  
  @media (max-width: 640px) {
    .content {
      width: calc(100% - 24px);
    }
  }
  
  .breadcrumbs {
    display: flex;
    align-items: center;
    padding-bottom: 48px;
  }
  
  .breadcrumbs span.slash {
    color: grey;
    margin: 0 4px;
  }
  
  .breadcrumbs * {
    font-size: var(--h6);
  }
  
  .catalogue .heading {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    margin-bottom: 48px;
  }
  
  .catalogue .heading h1 {
    margin-right: 16px;
  }
  
  .catalogue__categories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 0px;
    margin-bottom: 32px;
  }
  
  .catalogue__categories a {
    text-decoration: none;
    color: black;
    transition: all 0.2s ease-in-out;
  }
  
  .catalogue__categories li {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 4px;
    font-size: var(--h5);
    border: 2px solid white;
    opacity: 0.8;
  }
  .catalogue__categories li:hover {
    opacity: 1;
    border-color: var(--clr-1);
}
  .catalogue__categories li .image {
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .catalogue__categories li .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .catalogue .name {
    margin-right: 4px;
  }
  
  .catalogue .qty {
    font-size: var(--h6);
    color: rgb(207, 207, 207);
  }
  .catalogue .heading .count {
    color: rgb(224, 224, 224);
}
  </style>
  