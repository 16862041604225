export const advantages = [
    {
      title: "Перевага 1",
      image: new URL("@/assets/img/ouradvantages/image-id-7.webp", import.meta.url).href,
      img_mobile: new URL("@/assets/img/ouradvantages/image-id-7_s.webp", import.meta.url).href,
    },
    {
      title: "Перевага 2",
      image: new URL("@/assets/img/ouradvantages/image-id-8.webp", import.meta.url).href,
      img_mobile: new URL("@/assets/img/ouradvantages/image-id-8_s.webp", import.meta.url).href,
    },
    {
      title: "Перевага 3",
      image: new URL("@/assets/img/ouradvantages/image-id-9.webp", import.meta.url).href,
      img_mobile: new URL("@/assets/img/ouradvantages/image-id-9_s.webp", import.meta.url).href,
    },
    {
      title: "Перевага 4",
      image: new URL("@/assets/img/ouradvantages/image-id-16.webp", import.meta.url).href,
      img_mobile: new URL("@/assets/img/ouradvantages/image-id-16_s.webp", import.meta.url).href,
    },
    {
      title: "Перевага 5",
      image: new URL("@/assets/img/ouradvantages/image-id-11.webp", import.meta.url).href,
      img_mobile: new URL("@/assets/img/ouradvantages/image-id-11_s.webp", import.meta.url).href,
    },
  ];
  