import { createStore } from 'vuex';
import auth from './modules/auth';
import wishlist from './modules/wishlist';
import cart from './modules/cart';
import orders from './modules/orders';
import comments from './modules/comments';


export default createStore({
    modules: {
        auth, 
        wishlist,
        cart,
        orders,
        comments
    }
});
