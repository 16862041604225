import apiClient from './axios';

// Отримання всіх брендів
export const fetchAllBrands = async () => {
  try {
    const response = await apiClient.get('/shop/brands/all-brands/');
    return response.data;
  } catch (error) {
    console.error('Помилка завантаження всіх брендів:', error);
    throw error;
  }
};

export const fetchBrandBySlug = async (slug) => {
  try {
    const response = await apiClient.get(`/shop/brands/brand/`, { params: { slug } });
    return response.data;
  } catch (error) {
    console.error(`Помилка завантаження бренду з slug: ${slug}`, error);
    throw error;
  }
};
